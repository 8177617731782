import * as React from 'react';

import { ListGroup } from 'react-bootstrap';

// eslint-disable-next-line import/no-cycle
import AreaListItem from './AreaListItem';

import { AreaListPorps } from '../../@types/AreaList.d';

/**
 * Component to display a list of selected areas
 *
 * @param props
 */
const AreaList: React.FC<AreaListPorps> = (props: AreaListPorps) => {
  const { areas, removeArea, showLocalities, weekpart, show } = props;

  /**
   * Get the approximate height of the list to be able to run
   * more if less smooth animation when collapse/expand list
   */
  const getMaxHeight = (): number =>
    areas.reduce((acc, area) => {
      let rAcc = acc;
      rAcc += 54 + 40 * area.additionalAreas.length;
      return rAcc;
    }, 0);

  return (
    <ListGroup
      className={`area-list collapse-content ${
        show ? 'shown' : 'hidden'
      } height`}
      style={{ maxHeight: getMaxHeight() }}
    >
      {areas.map(area => (
        <AreaListItem
          key={area.areaKey}
          area={area}
          weekpart={weekpart}
          removeArea={removeArea}
          showLocalities={showLocalities}
        />
      ))}
    </ListGroup>
  );
};

export default AreaList;
