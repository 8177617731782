import * as React from 'react';

import { ListGroup } from 'react-bootstrap';

import LocalitiesListItem from './LocalitiesListItem';

import { LOCALITIES_LIST_EMPTY } from '../../constants/labels';

import { LocalitiesListProps } from '../../@types/LocalitiesList.d';

/**
 * Component to show the localities of an area (postcode only).
 *
 * @param props
 */
const LocalitiesList: React.FC<LocalitiesListProps> = (
  props: LocalitiesListProps
) => {
  const { localities, setLocalitySelection, weekpart } = props;

  return (
    <ListGroup>
      {localities && localities.length > 0 ? (
        localities.map(locality => (
          <LocalitiesListItem
            key={locality.localityKey}
            locality={locality}
            weekpart={weekpart}
            setLocalitySelection={setLocalitySelection}
          />
        ))
      ) : (
        <ListGroup.Item>{LOCALITIES_LIST_EMPTY}</ListGroup.Item>
      )}
    </ListGroup>
  );
};

export default LocalitiesList;
