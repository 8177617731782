import * as React from 'react';

import { ListGroup, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import AreaIconContainer from './AreaIcons';
import CirculationItem from '../common/CirculationItem';
import CustomTooltip from '../common/CustomTooltip';

import {
  LOCALITIES_TOOLTIP,
  ADDITIONAL_AREA_TOLLTIP,
} from '../../constants/labels';

import { AreaListItemProps } from '../../@types/AreaList.d';

/**
 * Component to show a list of selected areas
 */
const AreaListItem: React.FC<AreaListItemProps> = (
  props: AreaListItemProps
) => {
  const toolTipTarget = React.createRef<any>();
  const {
    additionalArea,
    area,
    parentAreaKey,
    parentName,
    weekpart,
    removeArea,
    showLocalities,
  } = props;

  // Determine if there are not selected localities or additional areas
  const showWarningIcon: boolean =
    !area.localities.every(locality => locality.selected) ||
    (additionalArea ?? false);

  // Convert the coverage of the area to a more readable format
  const coverage = (area.coverage * 100).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return (
    <ListGroup.Item
      as="div"
      className={`${additionalArea ? 'additional-area' : 'area-item'}`}
    >
      <Row ref={toolTipTarget} className="no-gutters">
        <Col sm={1} className="p-0 pl-2">
          {showWarningIcon && (
            <CustomTooltip
              tooltipId={`area-${area.id}-warn`}
              content={
                additionalArea
                  ? ADDITIONAL_AREA_TOLLTIP(parentName, parentAreaKey)
                  : LOCALITIES_TOOLTIP
              }
            >
              <div key={area.areaKey} className="icon-warn tooltip-icon">
                <FontAwesomeIcon icon={faExclamationTriangle} />
              </div>
            </CustomTooltip>
          )}
        </Col>
        <Col sm={2} className="p-0">
          {area.areaKey}
        </Col>
        <Col sm={4} className="p-0 text-eliptize">
          {`${area.areaName}${area.coverage !== 1 ? ` (${coverage}%)` : ''}`}
        </Col>
        <Col sm={3} className="p-0">
          <CirculationItem
            areaKey={area.areaKey}
            distributionType={area.distributionType}
            distributionTitle={area.distributorTitle}
            areaWeekpart={area.weekpart}
            circulation={area.circulation}
            weekpart={weekpart}
          />
        </Col>
        <Col sm={2} className="text-right p-0 pr-2">
          {removeArea && (
            <AreaIconContainer
              area={area}
              additionalArea={additionalArea}
              removeArea={removeArea}
              showLocalities={showLocalities}
            />
          )}
        </Col>
      </Row>
      {area.additionalAreas.length > 0 && (
        <ListGroup>
          {area.additionalAreas.map(additionalArea_ => (
            <AreaListItem
              key={additionalArea_.areaKey}
              additionalArea
              area={additionalArea_}
              parentAreaKey={area.areaKey}
              parentName={area.areaName}
              weekpart={weekpart}
              showLocalities={showLocalities}
            />
          ))}
        </ListGroup>
      )}
    </ListGroup.Item>
  );
};

export default AreaListItem;
