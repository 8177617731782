import '../../styles/DistributionTemplateList.scss';

import * as React from 'react';

import HistoryList from './HistoryList';

import ListHeader from '../common/listHeader/ListHeader';
import {
  HISTORY_TEMPLATE_LIST_TITLE,
  HISTORY_TEMPLATE_LIST_SEARCH_FIELD_HINT,
} from '../../constants/labels';
import { TRANSMISSION_TYPE_OFFER } from '../../constants/constants';

import {
  HistoryListContainerPorps,
  HistoryListContainerState,
} from '../../@types/HistoryList.d';

/**
 * Component to provide a container for a list of previously made orders and received offers.
 */
export default class HistoryContainer extends React.Component<
  HistoryListContainerPorps,
  HistoryListContainerState
> {
  constructor(props: HistoryListContainerPorps) {
    super(props);

    const { historyTemplates } = this.props;
    this.state = {
      filteredHistoryTemplateList: historyTemplates,
      searchString: '',
    };

    this.filterHistoryTemplateList = this.filterHistoryTemplateList.bind(this);
  }

  /**
   * Used to determine changes in the list and refresh it if there are changes.
   *
   * @param prevProps
   */
  componentDidUpdate(prevProps: HistoryListContainerPorps): void {
    const { historyTemplates } = this.props;

    if (
      JSON.stringify(historyTemplates) !==
      JSON.stringify(prevProps.historyTemplates)
    ) {
      const { searchString } = this.state;
      this.filterHistoryTemplateList(searchString);
    }
  }

  /**
   * Function to filter the history list by a provided string. This is triggered
   * by the ListSeaech component.
   *
   * @param searchString
   */
  filterHistoryTemplateList(searchString: string): void {
    const { historyTemplates } = this.props;
    let filteredHistoryTemplateList;
    if (searchString.length <= 0)
      filteredHistoryTemplateList = historyTemplates;
    else {
      const searchStringRegex = searchString
        .split('')
        .map(s => `[${s}]\\w*`)
        .join('');
      const regex = new RegExp(`\\w*${searchStringRegex}`, 'gi');
      filteredHistoryTemplateList = historyTemplates.filter(
        ({ actionName, distributionDay }) =>
          actionName?.match(regex) || distributionDay.match(regex)
      );
    }

    this.setState({ filteredHistoryTemplateList, searchString });
  }

  render(): JSX.Element {
    const {
      showHistoryTemplates,
      hideHistoryList,
      applyHistoryTemplate,
      transmissionType,
    } = this.props;
    const { filteredHistoryTemplateList, searchString } = this.state;
    return (
      <div
        className={`d-flex flex-column collapse-content ${
          showHistoryTemplates ? 'shown' : 'hidden'
        } width distribution-template-list-container`}
      >
        {showHistoryTemplates
          ? [
              <ListHeader
                key={1}
                hideList={hideHistoryList}
                searchString={searchString}
                filterList={this.filterHistoryTemplateList}
                listTitle={HISTORY_TEMPLATE_LIST_TITLE(
                  transmissionType === TRANSMISSION_TYPE_OFFER
                )}
                searchHint={HISTORY_TEMPLATE_LIST_SEARCH_FIELD_HINT(
                  transmissionType === TRANSMISSION_TYPE_OFFER
                )}
              />,
              <HistoryList
                key={2}
                offer={transmissionType === TRANSMISSION_TYPE_OFFER}
                historyTemplates={filteredHistoryTemplateList}
                applyHistoryTemplate={applyHistoryTemplate}
              />,
            ]
          : null}
      </div>
    );
  }
}
