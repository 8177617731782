import moment from 'moment';
import { extend, createEmpty } from 'ol/extent';
import OlMap from 'ol/Map';
import { Feature } from 'ol';
import { Size } from 'ol/size';
import jsPDF from 'jspdf';
import { saveAs } from 'file-saver';

import {
  EXPORT_CSV_HEADER_ARRAY_SUB,
  EXPORT_CSV_HEADER_ARRAY,
  DATA_FORMAT_CSV,
  DATA_FORMAT_XLSX,
  PDF_ORIENTATION,
  DATA_FORMAT_JPG,
  DATA_FORMAT_PNG,
  FREYPLUS_LOGO_PDF_EXPORT,
  PAPER_SIZES,
  REQUEST_IDENTIFIER_PRINT_MAP,
} from '../constants/constants';
import { PDF_COPYRIGHT, PDF_DATE } from '../constants/labels';
// eslint-disable-next-line import/no-cycle
import { exportAsExcel } from './api';

import {
  Area,
  Locality,
  LocalitySendFormat,
  AreaSendFormat,
  SubsidiarySendFormat,
} from '../@types/Area.d';
import {
  ClientLocation,
  Weekpart,
  PaperSize,
  ClientLocationSend,
  OpeningHours,
  OpeningHoursSend,
} from '../@types/Common.d';

/**
 * Get a Blob object from a given Base64 string.
 *
 * @param b64Data
 * @param contentType
 * @param sliceSize
 */
const b64toBlob = (b64Data: any, contentType = '', sliceSize = 512): Blob => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i += 1) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
};

/**
 * Get a CSV compatible format of an array of areas
 *
 * @param areas
 * @param subsidiaryName
 */
const areaExportFormat = (areas: Area[], subsidiaryName?: string): any[] =>
  areas.reduce((acc, area) => {
    let rAcc = acc;
    rAcc = [
      ...acc,
      ...[
        [subsidiaryName, area.areaName, area.areaKey, area.circulation].filter(
          val => val
        ),
      ],
    ];
    rAcc = [
      ...rAcc,
      area.additionalAreas.map(additionalArea =>
        [
          subsidiaryName,
          additionalArea.areaName,
          additionalArea.areaKey,
          additionalArea.circulation,
        ].filter(val => val)
      ),
    ];

    return rAcc;
  }, [] as any[]);

/**
 * Generate a download for a given set of data
 *
 * @param data
 * @param clientName
 * @param format
 */
const generateDownload = (
  data: any,
  clientName = 'csv_export',
  format = DATA_FORMAT_CSV
): void => {
  let downloadData;
  if (format === DATA_FORMAT_XLSX)
    downloadData = b64toBlob(
      data,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    );
  else downloadData = data;

  saveAs(
    downloadData,
    `${clientName.replace(/ /g, '_')}_${moment().format(
      'DDMMYYYY-HHmmss'
    )}.${format}`
  );
};

/**
 * Export all selected subsidiaries as a CSV file
 *
 * @param selectedSubsidiaries
 * @param clientName
 */
export const exportSubsidiaryCSV = (
  selectedSubsidiaries: ClientLocation[],
  clientName: string
): void => {
  const items = selectedSubsidiaries.reduce(
    (acc, selectedSubsidiary) => [
      ...acc,
      ...areaExportFormat(selectedSubsidiary.areas, selectedSubsidiary.name),
    ],
    [] as any[]
  );

  items.unshift(EXPORT_CSV_HEADER_ARRAY_SUB);
  const csvContent = `data:text/csv;charset=utf-8,${items
    .map(item => item.join(';'))
    .join('\n')}`;
  generateDownload(csvContent, clientName);
};

/**
 * Export all selected areas as a CSV file
 * @param areas
 */
export const exportAreaCSV = (areas: Area[]): void => {
  const items = areaExportFormat(areas);
  items.unshift(EXPORT_CSV_HEADER_ARRAY);

  const csvContent = `data:text/csv;charset=utf-8,${items
    .map(item => item.join(';'))
    .join('\n')}`;
  generateDownload(csvContent);
};

/**
 * Generate a PDF file containing an image of the
 * currently selected areas on the map.
 *
 * @param features
 * @param olMap
 */
export const printSelectionPDF = (
  features: Feature[],
  olMap: OlMap,
  printDimension: PaperSize,
  resolution: number,
  enableLoadingOverlay: (loading: boolean, requestIdentifier: string) => void
): void => {
  // If noting is selected exit (empty extend creates error)
  if (features.length === 0) return;

  // Convert pixels to cm
  const format = printDimension;
  const dim = (
    PAPER_SIZES.find(paperSize => paperSize.name === printDimension) ??
    PAPER_SIZES[5]
  ).dimensions;
  const width = Math.round((dim[0] * resolution) / 25.4);
  const height = Math.round((dim[1] * resolution) / 25.4);
  const size = olMap.getSize();

  // Get the extend of the given features
  const featuresExtent = features.reduce((acc, feature) => {
    if (feature) {
      const geometry = feature.getGeometry();
      if (geometry) {
        let accExtend = acc;
        accExtend = extend(accExtend, geometry.getExtent());
        return accExtend;
      }
    }

    return acc;
  }, createEmpty());

  // Later we reset the size of the map to fit the DIN A4 format.
  // If the map has rerendered we tanle a screen shot of the canvas
  // DOM element.
  olMap.once('rendercomplete', () => {
    const mapCanvas = document.createElement('canvas');
    mapCanvas.width = width;
    mapCanvas.height = height;

    const mapContext = mapCanvas.getContext('2d');

    if (!mapContext) return;

    Array.prototype.forEach.call(
      document.querySelectorAll('.ol-layer canvas'),
      (canvas: HTMLCanvasElement) => {
        if (canvas === null || canvas.width < 0) return;

        const { parentNode, style } = canvas;

        if (parentNode === null) return;

        const { opacity } = (parentNode as HTMLElement).style;

        mapContext.globalAlpha = opacity === '' ? 1 : +opacity;
        const { transform } = style;
        const transformMatch = transform.match(/^matrix\(([^(]*)\)$/);

        if (transformMatch === null) return;

        const matrix = transformMatch[1].split(',').map(Number) as [
          (DOMMatrix2DInit | undefined)?
        ];

        CanvasRenderingContext2D.prototype.setTransform.apply(
          mapContext,
          matrix
        );
        mapContext.drawImage(canvas, 0, 0);
      }
    );
    const border = 10;
    const today = moment();

    // eslint-disable-next-line new-cap
    const pdf = new jsPDF(PDF_ORIENTATION, 'mm', format.toLowerCase());
    pdf.addImage(
      mapCanvas.toDataURL('image/jpeg'),
      DATA_FORMAT_JPG,
      border,
      border,
      dim[0] - 2 * border,
      dim[1] - 2 * border
    );
    // Insert a frame around the picture
    pdf.rect(border, border, dim[0] - 2 * border, dim[1] - 2 * border);
    pdf.setFont('Arial');
    pdf.setFontSize(6);
    // Insert copyright and current date below the picture
    pdf.text(border + 6, dim[1] - 5, PDF_COPYRIGHT);
    pdf.text(
      dim[0] - (border + 21),
      dim[1] - 5,
      `${PDF_DATE}: ${today.format('dd, DD.MM.YYYY')}`
    );
    // Add a freyplus logo
    pdf.addImage(
      FREYPLUS_LOGO_PDF_EXPORT,
      DATA_FORMAT_PNG,
      border,
      dim[1] - 8,
      5,
      5
    );
    // Initiate a download for the pdf file
    pdf.save(`planung_${today.format('DD.MM.YYYY')}.pdf`);

    // Reset original map size
    olMap.setSize(size);
    olMap
      .getView()
      .fit(featuresExtent, { size, padding: [100, 100, 100, 100] });

    enableLoadingOverlay(false, REQUEST_IDENTIFIER_PRINT_MAP);
  });

  // Adjust the maps size to a DIN A4 compatible size
  const printSize = [width, height] as Size;
  olMap.setSize(printSize);
  olMap
    .getView()
    .fit(featuresExtent, { size: printSize, padding: [100, 100, 100, 100] });
};

/**
 * Get a locality in a minimal format the api understands
 *
 * @param locality
 */
export const getLocalitySend = (locality: Locality): LocalitySendFormat =>
  ({
    id: locality.id,
    localityKey: locality.localityKey,
    selected: locality.selected,
  } as LocalitySendFormat);

/**
 * Get an array of localities in a minimal format the api
 * understands.
 *
 * @param localities
 */
export const getLocalitiesSend = (
  localities: Locality[]
): LocalitySendFormat[] =>
  localities.map(locality => getLocalitySend(locality)) as LocalitySendFormat[];

/**
 * Get an area in a minimal format the api understands
 *
 * @param area
 */
export const getAreaSend = (area: Area): AreaSendFormat =>
  ({
    areaKey: area.areaKey,
    localities: getLocalitiesSend(area.localities),
  } as AreaSendFormat);

/**
 * Get an array of areas in a minimal format the api understands
 * @param areas
 */
export const getAreasSend = (areas: Area[]): AreaSendFormat[] =>
  areas.map(area => getAreaSend(area)) as AreaSendFormat[];

export const getSubsidiariesSend = (
  subsidiaries: ClientLocation[]
): SubsidiarySendFormat[] =>
  subsidiaries.map(subsidiary => ({
    id: subsidiary.id,
    areas: getAreasSend(subsidiary.areas),
  })) as SubsidiarySendFormat[];

/**
 * Export the selection on a format the api needs
 * to genrate an excel file from it.
 *
 * @param clientId
 * @param clientName
 * @param weekpart
 * @param userEmail
 * @param selectedSubsidiaries
 * @param areas
 */
export const exportExcel = async (
  clientId: string,
  clientName: string,
  weekpart: Weekpart,
  userEmail: string,
  selectedSubsidiaries?: ClientLocation[],
  areas?: Area[]
): Promise<void> => {
  let locations;
  if (selectedSubsidiaries) {
    locations = getSubsidiariesSend(selectedSubsidiaries);
  } else if (areas) {
    locations = {
      '-1': getAreasSend(areas),
    };
  }

  const data = {
    clientId,
    weekpart,
    locations,
    userEmail,
  };

  const excelResult = await exportAsExcel(data);

  if (!excelResult) return;

  generateDownload(excelResult, clientName, DATA_FORMAT_XLSX);
};

/**
 * Converts the opening hours object into an array
 *
 * @param openingHours
 */
export const getOpeningHoursSend = (
  openingHours: OpeningHours
): OpeningHoursSend[] =>
  Object.keys(openingHours).reduce((acc, day) => {
    const openingHour = openingHours[day];

    if (!openingHour) return acc;

    const {
      morningFrom,
      morningTo,
      noonFrom,
      noonTo,
      continuouslyOpen,
    } = openingHour;

    return [
      ...acc,
      ...[{ morningFrom, morningTo, noonFrom, noonTo, continuouslyOpen, day }],
    ];
  }, [] as OpeningHoursSend[]);

export const getOpeningHours = (
  openingHoursSend?: OpeningHoursSend[]
): OpeningHours =>
  openingHoursSend?.reduce((acc, openingHourSend) => {
    const {
      id,
      morningFrom,
      morningTo,
      noonFrom,
      noonTo,
      day,
      continuouslyOpen,
    } = openingHourSend;
    acc[day] = {
      id,
      morningFrom,
      morningTo,
      noonFrom,
      noonTo,
      continuouslyOpen,
    };

    return acc;
  }, {} as OpeningHours) ?? {};

/**
 * Get an array of areas in a extended format the api understands for
 * creation and updates.
 *
 * @param areas
 */
export const getClientLocationSend = (
  subsidiary: ClientLocation
): ClientLocationSend => {
  const {
    addressName,
    city,
    colorSelectedFill,
    email,
    housenumber,
    id,
    lat,
    lon,
    name,
    number,
    openingHours,
    phone,
    planable,
    poi,
    postcode,
    street,
  } = subsidiary;

  return {
    addressName,
    city,
    colorSelectedFill,
    email,
    housenumber,
    id,
    lat,
    lon,
    name,
    number,
    openingHours: getOpeningHoursSend(openingHours),
    phone,
    planable,
    poi,
    postcode,
    street,
  };
};

export const getClientLocationSends = (
  subsidiaries: ClientLocation[]
): ClientLocationSend[] =>
  subsidiaries.map(subsidiary => getClientLocationSend(subsidiary));
