import * as React from 'react';

import { Modal, Button, Col } from 'react-bootstrap';

import SubsidiaryDistributionTemplateList from './SubsidiaryDistributionTemplateList';

import {
  BUTTON_TITLE_ABORT,
  BUTTON_TITLE_ACCEPT,
  MODAL_TITLE_SUBSIDIARY_DISTRIBUTION_TEMPLATES,
} from '../../../constants/labels';

import {
  SubsidiaryDistributionTemplateModalProps,
  SubsidiaryDistributionTemplateModalState,
} from '../../../@types/Modal.d';
import {
  ClientLocation,
  SubsidiaryDistributionTemplate,
} from '../../../@types/Common.d';

/**
 * Modal dialog to show a list of a subsidiarys distribution templates
 */
export default class SubsidiaryDistributionTemplateModal extends React.Component<
  SubsidiaryDistributionTemplateModalProps,
  SubsidiaryDistributionTemplateModalState
> {
  constructor(props: SubsidiaryDistributionTemplateModalProps) {
    super(props);

    this.state = {};

    this.onHide = this.onHide.bind(this);
    this.onClickApply = this.onClickApply.bind(this);

    this.setSubsidiary = this.setSubsidiary.bind(this);

    this.selectSubsidiaryDistributionTemplate = this.selectSubsidiaryDistributionTemplate.bind(
      this
    );
  }

  /**
   * Method to hide the modal dialog
   */
  onHide(): void {
    this.setState({ subsidiary: undefined }, () => {
      const { showModal } = this.props;

      showModal(false);
    });
  }

  /**
   * Click action to load the selected susidiary distribution template
   *
   * @param event
   */
  onClickApply(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    const { applySubsidiaryDistributionTemplate } = this.props;
    const { selectedSubsidiaryDistributionTemplate } = this.state;

    if (!selectedSubsidiaryDistributionTemplate) return;

    applySubsidiaryDistributionTemplate(selectedSubsidiaryDistributionTemplate);
    this.onHide();
    event.preventDefault();
  }

  /**
   * Set the subsidiary which distribution templates are displayed
   *
   * @param subsidiary
   */
  setSubsidiary(subsidiary?: ClientLocation): void {
    this.setState({ subsidiary });
  }

  /**
   * Set the selected subsidiary distribution item
   *
   * @param selectedSubsidiaryDistributionTemplate
   */
  selectSubsidiaryDistributionTemplate(
    selectedSubsidiaryDistributionTemplate: SubsidiaryDistributionTemplate
  ): void {
    this.setState({ selectedSubsidiaryDistributionTemplate });
  }

  render(): JSX.Element {
    const {
      show,
      removeSubsidiaryDistributionTemplate,
      container,
    } = this.props;
    const { subsidiary, selectedSubsidiaryDistributionTemplate } = this.state;

    return (
      <Modal
        show={show}
        onHide={this.onHide}
        container={container.current}
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {MODAL_TITLE_SUBSIDIARY_DISTRIBUTION_TEMPLATES(subsidiary?.name)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SubsidiaryDistributionTemplateList
            subsidiaryDistributionTemplates={
              subsidiary?.subsidiaryDistributionTemplates ?? []
            }
            selectedSubsidiaryDistributionTemplate={
              selectedSubsidiaryDistributionTemplate
            }
            subsidiary={subsidiary}
            selectSubsidiaryDistributionTemplate={
              this.selectSubsidiaryDistributionTemplate
            }
            removeSubsidiaryDistributionTemplate={
              removeSubsidiaryDistributionTemplate
            }
          />
        </Modal.Body>
        <Modal.Footer>
          <Col className="p-0">
            <Button
              className="ci modal-footer-button"
              onClick={this.onClickApply}
            >
              {BUTTON_TITLE_ACCEPT}
            </Button>
          </Col>
          <Col className="p-0">
            <Button className="ci modal-footer-button" onClick={this.onHide}>
              {BUTTON_TITLE_ABORT}
            </Button>
          </Col>
        </Modal.Footer>
      </Modal>
    );
  }
}
