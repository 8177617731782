import { Feature } from 'ol';
import { transform as projTransform } from 'ol/proj';
import Point from 'ol/geom/Point';

import { generateLocationStyle } from './featureStyle';
import {
  COORD_EPSG_4326,
  COORD_EPSG_3857,
  FEATURE_TYPE_LOCATION,
} from '../constants/constants';

import { Area } from '../@types/Area.d';
import { ClientLocation } from '../@types/Common.d';

/**
 * Get a minimal area from a given feature
 *
 * @param feature
 */
export const getAreaFromFeature = (
  feature: Feature | undefined
): Area | undefined => {
  if (feature) {
    const area = {} as Area;
    area.id = feature.getId() ? feature.getId() : feature.get('areaKey');
    area.areaName = feature.get('name');
    area.areaKey = feature.get('areaKey');
    area.feature = feature;

    return area;
  }

  return undefined;
};

/**
 * Get a feature from a given client location/subsidiary
 * @param location
 * @param selected
 */
export const getLocationFeature = (
  location: ClientLocation,
  selected: boolean = false
): Feature => {
  let poiIcon = selected
    ? location.poi.active ?? require('../resources/img/POI.png')
    : location.poi.inactive ?? require('../resources/img/POI.png');

  if (poiIcon === '') poiIcon = require('../resources/img/POI.png');

  const locationFeature = new Feature({
    geometry: new Point(
      projTransform(
        [location.lon, location.lat],
        COORD_EPSG_4326,
        COORD_EPSG_3857
      )
    ),
    type: FEATURE_TYPE_LOCATION,
    name: location.name,
    street: location.street,
    housenumber: location.housenumber,
    postcode: location.postcode,
    city: location.city,
    planable: location.planable,
  });

  locationFeature.setStyle(generateLocationStyle(poiIcon));

  return locationFeature;
};

/**
 * Get an array of features from a given array of client locations/subsidiaries
 *
 * @param locations
 */
export const getLocationFeatures = (locations: ClientLocation[]): Feature[] =>
  locations.map(location => getLocationFeature(location));
