/* eslint-disable no-self-compare */
import * as React from 'react';

import { Col, Row } from 'react-bootstrap';

import { PRICE_LABEL, PRICE_SUM_LABEL } from '../../constants/labels';

import { PriceItemProps } from '../../@types/App.d';

/**
 * Component to display the price of the current selection.
 *
 * @param props
 */
const PriceItem: React.FC<PriceItemProps> = (props: PriceItemProps) => {
  const { price } = props;

  return (
    <Row className="no-gutters total-circulation-container">
      <Col sm={12} className="total-circulation">
        <Row className="no-gutters">
          <Col sm={3} className="p-0">
            {PRICE_LABEL}
          </Col>
          <Col className="text-right p-0">{PRICE_SUM_LABEL(price)}</Col>
        </Row>
      </Col>
    </Row>
  );
};

export default PriceItem;
