import * as React from 'react';

import { ListGroup, Row, Col } from 'react-bootstrap';

import { faTrash, faPen, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CustomTooltip from '../common/CustomTooltip';

import {
  TOOLTIP_DISTIRBUTION_TEMPLATE_REMOVE,
  TOOLTIP_DISTIRBUTION_TEMPLATE_EDIT,
  TOOLTIP_DISTIRBUTION_TEMPLATE_UPDATE,
} from '../../constants/labels';

import { DistributionTemplateListItemPorps } from '../../@types/DistributionTemplateList.d';

/**
 * Component to display a distribution template item inside the DistributionTemplateList
 *
 * @param props
 */
const DistributionTemplateListItem: React.FC<DistributionTemplateListItemPorps> = (
  props: DistributionTemplateListItemPorps
) => {
  const {
    distributionTemplate,
    planningRestriction,
    applyDistributionTemplate,
    deleteDistributionTemplate,
    updateDistributionTemplate,
  } = props;

  /**
   * Click action for selecting a distribution template and show it on the map.
   *
   * @param event
   */
  const onClickItem = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ): void => {
    event.stopPropagation();

    applyDistributionTemplate(distributionTemplate);
  };

  /**
   * Click action for editing a distribution template's name.
   *
   * @param event
   */
  const onClickEdit = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    event.stopPropagation();

    updateDistributionTemplate(distributionTemplate, false);
  };

  /**
   * Key down action for edit a distribution template's name.
   *
   * @param event
   */
  const onKeyDownEdit = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    event.stopPropagation();
    if (event.keyCode === 13)
      updateDistributionTemplate(distributionTemplate, false);
  };

  /**
   * Click action for update a distribution template's area selection.
   *
   * @param event
   */
  const onClickUpdate = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    event.stopPropagation();

    updateDistributionTemplate(distributionTemplate, true);
  };

  /**
   * Key down action for update a distribution template's area selection.
   *
   * @param event
   */
  const onKeyDownUpdate = (
    event: React.KeyboardEvent<HTMLDivElement>
  ): void => {
    event.stopPropagation();
    if (event.keyCode === 13)
      updateDistributionTemplate(distributionTemplate, true);
  };

  /**
   * Click action for deleteing a distribution template.
   *
   * @param event
   */
  const onClickDelete = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    event.stopPropagation();

    deleteDistributionTemplate(distributionTemplate);
  };

  /**
   * Key down action for deleteing a distribution template.
   *
   * @param event
   */
  const onKeyDownDelete = (
    event: React.KeyboardEvent<HTMLDivElement>
  ): void => {
    event.stopPropagation();
    if (event.keyCode === 13) deleteDistributionTemplate(distributionTemplate);
  };

  return (
    <ListGroup.Item onClick={onClickItem} className="hover-item">
      <Row className="no-gutters">
        <Col>{distributionTemplate.name}</Col>
      </Row>
      {planningRestriction === 'NONE' && (
        <>
          <Row className="no-gutters">
            <Col>
              <div className="separator" />
            </Col>
          </Row>
          <Row className="no-gutters justify-content-start">
            <Col xs={1} className="text-right">
              <CustomTooltip
                tooltipId={`template-${distributionTemplate.id}-update`}
                content={TOOLTIP_DISTIRBUTION_TEMPLATE_EDIT}
                placement="right-start"
              >
                <div
                  role="button"
                  tabIndex={0}
                  onClick={onClickEdit}
                  onKeyDown={onKeyDownEdit}
                  className="hover-icon text-center"
                >
                  <FontAwesomeIcon icon={faPen} />
                </div>
              </CustomTooltip>
            </Col>
            <Col xs={1} className="text-right">
              <CustomTooltip
                tooltipId={`template-${distributionTemplate.id}-rem`}
                content={TOOLTIP_DISTIRBUTION_TEMPLATE_REMOVE}
                placement="right-start"
              >
                <div
                  role="button"
                  tabIndex={0}
                  onClick={onClickDelete}
                  onKeyDown={onKeyDownDelete}
                  className="hover-icon text-center"
                >
                  <FontAwesomeIcon icon={faTrash} />
                </div>
              </CustomTooltip>
            </Col>
            <Col className="icon-col" />
            <Col xs={1} className="text-right">
              <CustomTooltip
                tooltipId={`template-${distributionTemplate.id}-update`}
                content={TOOLTIP_DISTIRBUTION_TEMPLATE_UPDATE}
                placement="left-start"
              >
                <div
                  role="button"
                  tabIndex={0}
                  onClick={onClickUpdate}
                  onKeyDown={onKeyDownUpdate}
                  className="hover-icon text-center"
                >
                  <FontAwesomeIcon icon={faSave} />
                </div>
              </CustomTooltip>
            </Col>
          </Row>
        </>
      )}
    </ListGroup.Item>
  );
};

export default DistributionTemplateListItem;
