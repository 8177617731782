import * as React from 'react';

import { Col, Row, ListGroup, Form } from 'react-bootstrap';
import {
  faTrash,
  faChevronUp,
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AreaList from './AreaList';
import CirculationItem from '../common/CirculationItem';
import CustomTooltip from '../common/CustomTooltip';

import {
  TOOLTIP_SELECTED_SUBSIDIARY_REMOVE,
  TOOLTIP_SELECTED_SUBSIDIARY_HIDE,
  PRICE_SUM_LABEL,
} from '../../constants/labels';

import { calculateSubsidiaryCalculation } from '../../util/areaUtil';

import {
  SubsidiaryAreaListItemProps,
  SubsidiaryAreaListItemState,
} from '../../@types/AreaList.d';

/**
 * Component to display a subsidiary as a listitem with its selected areas.
 * Also provides user interaction to remove and collapse.
 */
export default class SubsidiaryAreaListItem extends React.Component<
  SubsidiaryAreaListItemProps,
  SubsidiaryAreaListItemState
> {
  constructor(props: SubsidiaryAreaListItemProps) {
    super(props);

    const { show } = this.props;

    this.state = { show };

    this.onClickCollapse = this.onClickCollapse.bind(this);
    this.onClickRemove = this.onClickRemove.bind(this);
    this.onKeyDownCollapse = this.onKeyDownCollapse.bind(this);
    this.onKeyDownRemove = this.onKeyDownRemove.bind(this);
    this.onChangeHideSelection = this.onChangeHideSelection.bind(this);
  }

  componentDidUpdate(prevProps: SubsidiaryAreaListItemProps): void {
    const { show } = this.props;

    // eslint-disable-next-line react/no-did-update-set-state
    if (prevProps.show !== show) this.setState({ show });
  }

  /**
   * Click action to collapse/expand the list of selected areas of the subsidiary
   *
   * @param event
   */
  onClickCollapse(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
    event.stopPropagation();
    const { show } = this.state;
    this.setState({ show: !show });
  }

  /**
   * Key down action to collapse/expand the list of selected areas of the subsidiary
   *
   * @param event
   */
  onKeyDownCollapse(event: React.KeyboardEvent<HTMLDivElement>): void {
    event.stopPropagation();
    const { show } = this.state;
    this.setState({ show: !show });
  }

  /**
   * Click action to remove the subsidiary and its areas from the selection
   *
   * @param event
   */
  onClickRemove(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
    event.stopPropagation();
    const { removeSubsidiary, subsidiary } = this.props;

    removeSubsidiary(subsidiary);
  }

  /**
   * key down action to remove the subsidiary and its areas from the selection
   *
   * @param event
   */
  onKeyDownRemove(event: React.KeyboardEvent<HTMLDivElement>): void {
    event.stopPropagation();
    const { removeSubsidiary, subsidiary } = this.props;

    removeSubsidiary(subsidiary);
  }

  /**
   * Hide or show the selected areas of the subsidiary on the map.
   *
   * @param event
   */
  onChangeHideSelection(event: React.ChangeEvent<HTMLInputElement>): void {
    event.stopPropagation();
    const { subsidiary, hideSubsidiarySelection } = this.props;

    hideSubsidiarySelection(subsidiary, event.currentTarget.checked);
  }

  render(): JSX.Element {
    const { subsidiary, weekpart, removeArea, showLocalities } = this.props;
    const { show } = this.state;
    return (
      <ListGroup.Item className="subsidiary-area-list-item">
        <Row className="subsidiary-area-list-row">
          <Col md={1} className="p-0 pl-2">
            <div
              role="button"
              className="hover-icon"
              tabIndex={0}
              onClick={this.onClickCollapse}
              onKeyDown={this.onKeyDownCollapse}
            >
              <FontAwesomeIcon icon={show ? faChevronDown : faChevronUp} />
            </div>
          </Col>
          <Col className="p-0">
            <Row className="no-gutters">
              <Col className="text-eliptize text-bold p-0">
                {subsidiary.name}
              </Col>
              <Col md={3} className="p-0">
                <CirculationItem
                  weekpart={weekpart}
                  circulation={calculateSubsidiaryCalculation(subsidiary)}
                />
              </Col>
            </Row>
            <Row className="no-gutters">
              <Col className="p-0 text-right">
                {PRICE_SUM_LABEL(subsidiary.price)}
              </Col>
            </Row>
          </Col>
          <Col md={2} className="p-0 pr-2">
            <Row className="no-gutters subsidiary-icons">
              <Col xs={6} className="text-right">
                <CustomTooltip
                  tooltipId={`selected-subsidiary-${subsidiary.id}-hide`}
                  content={TOOLTIP_SELECTED_SUBSIDIARY_HIDE}
                >
                  <Form.Check
                    id={`hide-selection-check-${subsidiary.id}`}
                    custom
                    type="checkbox"
                    onChange={this.onChangeHideSelection}
                    checked={subsidiary.show}
                    label=""
                  />
                </CustomTooltip>
              </Col>
              <Col xs={6} className="text-right">
                <CustomTooltip
                  tooltipId={`selected-subsidiary-${subsidiary.id}-remove`}
                  content={TOOLTIP_SELECTED_SUBSIDIARY_REMOVE}
                >
                  <div
                    role="button"
                    className="icon-remove hover-icon"
                    tabIndex={0}
                    onClick={this.onClickRemove}
                    onKeyDown={this.onKeyDownRemove}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </div>
                </CustomTooltip>
              </Col>
            </Row>
          </Col>
        </Row>
        {subsidiary.areas && subsidiary.areas.length > 0 && (
          <Row className="subsidiary-area-list-row">
            <AreaList
              show={show}
              weekpart={weekpart}
              areas={subsidiary.areas}
              removeArea={removeArea}
              showLocalities={showLocalities}
            />
          </Row>
        )}
      </ListGroup.Item>
    );
  }
}
