import axios from 'axios';
import moment from 'moment';

import {
  PATH_CLIENT_DATA,
  PATH_AREAS,
  PATH_SUBSIDIARY_UPDATE,
  PATH_SUBSIDIARY_DELETE,
  URL_NOMINATIM,
  OPENCAGE_API_KEY,
  PATH_SUBSIDIARY_NEW,
  PATH_EXPORT_EXCEL,
  PATH_DISTRIBUTION_TEMPLATE_NEW,
  PATH_DISTRIBUTION_TEMPLATE_DELETE,
  PATH_DISTRIBUTION_TEMPLATE_UPDATE,
  PATH_SUBSIDIARY_DISTRIBUTION_TEMPLATE_DELETE,
  PATH_SUBSIDIARY_DISTRIBUTION_TEMPLATE_NEW,
  PATH_CALCULATION_FPP,
  PATH_GET_CLIENT_HISTORY,
  PATH_GET_CLIENT_HISTORY_ITEM,
  URL_ORS,
  ORS_API_KEY,
  OSR_ISOCHRONES,
  PATH_CLIENT_AREA_META_DATA,
  PATH_GET_POIS,
} from '../constants/network';
import {
  COUNTRY_CODE_DE,
  DATA_FORMAT_XLSX,
  WEEKPART_BEST,
  COUNTRY_CODE_CH,
} from '../constants/constants';

// eslint-disable-next-line import/no-cycle
import { extractAreas, extractPrices } from './responseUtil/areaResponseUtil';
// eslint-disable-next-line import/no-cycle
import {
  extractClient,
  extractClientLocation,
  extractDistributionTemplate,
  extractSubsidiaryDistributionTemplate,
  extractOfferTemplates,
  extractOrderTemplates,
  extractOfferTemplate,
  extractOrderTemplate,
  extractClientMetaData,
} from './responseUtil/clientResponseUtil';

import config from '../config';

import { SubsidiarySendFormat, Area } from '../@types/Area.d';
import {
  Weekpart,
  ClientLocation,
  Coordinates,
  DistributionTemplate,
  Product,
  SubsidiaryDistributionTemplate,
  Client,
  OSRProfiles,
  AreaDataFormat,
  DynamicPlaningParam,
  OfferHistoryTemplate,
  OrderHistoryTemplate,
  ClientMetaData,
  PriceResult,
  ClientLocationSend,
  POI,
} from '../@types/Common.d';
import { Address } from '../@types/Modal.d';

/**
 * The axios client used to make api calls
 */
export const axiosClient = axios.create({
  baseURL: config.general.apiUrl,
  responseType: 'json',
});

/**
 * Default headers for the fra api
 */
const headers = {
  'X-API-Key-Token': config.general.apiKey ?? '',
  'Content-Type': 'application/json',
  'Cache-Control': 'no-cache',
};

/**
 * Print an error message to the console.
 *
 * @param error
 */
export const printError = (error: any): void => {
  // eslint-disable-next-line no-console
  if (config.general.printToConsole) console.log(error);
};

/**
 * Request client data by a clients id
 *
 * @param clientId
 */
export const getClientData = (clientId: number): Promise<void | Client> =>
  axiosClient
    .get(PATH_CLIENT_DATA(clientId), {
      headers,
    })
    .then(
      response => {
        return extractClient(response.data);
      },
      error => printError(error)
    )
    .catch(error => printError(error));

/**
 * Get area data from the api by sending all selected
 * areas within the body. It's neccessary to send all
 * because of dependencies between some areas.
 *
 * @param areas
 * @param weekpart
 * @param distributionWeek
 * @param distributionYear
 * @param dynamicPlaningParams
 */
export const getAreaData = (
  areas: AreaDataFormat[],
  weekpart: Weekpart,
  distributionWeek?: number,
  distributionYear?: number,
  dynamicPlaningParams?: DynamicPlaningParam[]
): Promise<void | Area[]> =>
  axiosClient
    .post(
      PATH_AREAS,
      {
        areas,
        ...(dynamicPlaningParams ? { dynamicPlaningParams } : {}),
        ...(weekpart !== '' && { weekpart }),
        ...(weekpart === WEEKPART_BEST && {
          distributionWeek,
          distributionYear,
        }),
      },
      {
        headers,
      }
    )
    .then(
      response => extractAreas(response.data),
      error => printError(error)
    )
    .catch(error => printError(error));

/**
 * Create a new client location/subsidiary
 *
 * @param clientLocation
 * @param clientId
 */
export const createClientLocation = (
  clientLocation: ClientLocationSend,
  clientId: number
): Promise<void | ClientLocation> =>
  axiosClient
    .post(PATH_SUBSIDIARY_NEW(clientId), clientLocation, {
      headers,
    })
    .then(
      response => {
        return extractClientLocation(response.data);
      },
      error => printError(error)
    )
    .catch(error => printError(error));

/**
 * Updates a existing client location/subsidiary
 *
 * @param clientLocation
 */
export const updateClientLocation = (
  clientLocation: ClientLocationSend
): Promise<void | ClientLocation> =>
  axiosClient
    .put(PATH_SUBSIDIARY_UPDATE, clientLocation, {
      headers,
    })
    .then(
      response => {
        return extractClientLocation(response.data);
      },
      error => printError(error)
    )
    .catch(error => printError(error));

/**
 * Delete a existing client location/subsidiary
 *
 * @param clientLocation
 */
export const deleteClientLocation = (
  clientLocation: ClientLocation
): Promise<any> =>
  axiosClient
    .delete(PATH_SUBSIDIARY_DELETE(clientLocation.id), { headers })
    .then(
      response => {
        return response;
      },
      error => printError(error)
    )
    .catch(error => printError(error));

/**
 * Create a new distribution template for a given client id
 *
 * @param clientId
 * @param distributionTemplate
 */
export const createDistributionTemplate = (
  clientId: number,
  distributionTemplate: DistributionTemplate
): Promise<void | DistributionTemplate> =>
  axiosClient
    .post(PATH_DISTRIBUTION_TEMPLATE_NEW(clientId), distributionTemplate, {
      headers,
    })
    .then(
      response => {
        return extractDistributionTemplate(response.data);
      },
      error => printError(error)
    )
    .catch(error => printError(error));

/**
 * Update a existing distribution template.
 *
 * @param distributionTemplate
 */
export const updateDistributionTemplate = (
  clientId: number,
  distributionTemplate: DistributionTemplate
): Promise<void | DistributionTemplate> =>
  axiosClient
    .put(PATH_DISTRIBUTION_TEMPLATE_UPDATE(clientId), distributionTemplate, {
      headers,
    })
    .then(
      response => {
        return extractDistributionTemplate(response.data);
      },
      error => {
        printError(error);
      }
    )
    .catch(error => printError(error));

/**
 * Delete a existing distribution template.
 *
 * @param distributionTemplate
 */
export const deleteDistributionTemplate = (
  distributionTemplate: DistributionTemplate
): Promise<any> =>
  axiosClient
    .delete(PATH_DISTRIBUTION_TEMPLATE_DELETE(distributionTemplate.id), {
      headers,
    })
    .then(
      response => {
        return response.status;
      },
      error => printError(error)
    )
    .catch(error => printError(error));

/**
 * Delete a existing subsidiary distribution template
 *
 * @param client
 * @param clientLocation
 * @param subsidiaryDistributionTemplate
 */
export const deleteSubsidiaryDistributionTemplate = (
  client: Client,
  clientLocation: ClientLocation,
  subsidiaryDistributionTemplate: SubsidiaryDistributionTemplate
): Promise<any> =>
  axiosClient
    .delete(
      PATH_SUBSIDIARY_DISTRIBUTION_TEMPLATE_DELETE(
        client.id,
        clientLocation.id,
        subsidiaryDistributionTemplate.id
      ),
      { headers }
    )
    .then(
      response => {
        return response.status;
      },
      error => printError(error)
    )
    .catch(error => printError(error));

/**
 * Create a new subsidiary distribution template
 *
 * @param client
 * @param clientLocation
 * @param subsidiaryDistributionTemplate
 */
export const createSubsidiaryDistributionTemplate = (
  client: Client,
  clientLocation: ClientLocation,
  subsidiaryDistributionTemplate: SubsidiaryDistributionTemplate
): Promise<void | SubsidiaryDistributionTemplate> =>
  axiosClient
    .post(
      PATH_SUBSIDIARY_DISTRIBUTION_TEMPLATE_NEW(client.id, clientLocation.id),
      subsidiaryDistributionTemplate,
      { headers }
    )
    .then(
      response => {
        return extractSubsidiaryDistributionTemplate(
          response.data,
          clientLocation.id
        );
      },
      error => printError(error)
    )
    .catch(error => printError(error));

/**
 * Search for an address string and receive information about the address.
 * This currently uses a external nominatim api endpoint and is mainly used
 * to receive coordinates of an address.
 *
 * @param addressString
 */
export const searchAddress = (
  addressString: string
): Promise<void | Address[]> =>
  axiosClient
    .get(URL_NOMINATIM, {
      params: {
        q: addressString,
        key: OPENCAGE_API_KEY,
        language: COUNTRY_CODE_DE.toLowerCase(),
        no_annotations: 1,
        min_confidence: 5,
        countrycode: `${COUNTRY_CODE_DE.toLowerCase()},${COUNTRY_CODE_CH.toLowerCase()}}`,
      },
    })
    .then(
      response =>
        response.data.features.map((address: any) => {
          const coordinates: Coordinates = {
            lat: address.geometry.coordinates[1],
            lon: address.geometry.coordinates[0],
          };
          return {
            coordinates,
            name: address.properties.formatted,
            selected: false,
          } as Address;
        }),
      error => printError(error)
    )
    .catch(error => printError(error));

/**
 * Search for an address with a set of given coordinates.
 * This currently uses a external nominatim api endpoint.
 *
 * @param coordinates
 */
export const searchCoordinates = (coordinates: Coordinates): Promise<any> =>
  axiosClient
    .get(URL_NOMINATIM, {
      params: {
        q: `${coordinates.lat},${coordinates.lon}`,
        key: OPENCAGE_API_KEY,
        language: COUNTRY_CODE_DE.toLowerCase(),
        no_annotations: 1,
        countrycode: `${COUNTRY_CODE_DE.toLowerCase()},${COUNTRY_CODE_CH.toLowerCase()}`,
      },
    })
    .then(
      () => {},
      error => printError(error)
    );

/**
 * Get the total price for the current selection. All areas have
 * to be submitted to the body because there can be different
 * priceing for different areas.
 * If there are no subsidiaries the locations array should contain
 * one entry with the id -1.
 *
 * @param locations
 * @param weekpart
 * @param product
 */
export const getTotalPrice = (
  locations: SubsidiarySendFormat[],
  weekpart: Weekpart,
  product?: Product
): Promise<void | PriceResult> =>
  axiosClient
    .post(
      PATH_CALCULATION_FPP,
      { locations, productId: product?.id ?? -1, weekpart },
      { headers }
    )
    .then(
      response => extractPrices(response.data) as PriceResult,
      error => printError(error)
    );

/**
 * Export the current selection as an excel file.
 *
 * @param data
 * @param format
 */
export const exportAsExcel = async (
  data: any,
  format: string = DATA_FORMAT_XLSX
): Promise<undefined | string> => {
  const { clientId, weekpart, locations, userEmail } = data;

  const response = await fetch(
    `${config.general.apiUrl}${PATH_EXPORT_EXCEL}?format=${format}`,
    {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers,
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({ clientId, weekpart, locations, userEmail }),
    }
  );

  if (response.status < 300) return response.text();

  return undefined;
};

/**
 * Get a previously made order or received offer by its id,
 *
 * @param client
 * @param order
 */
export const getHistoryData = (
  client: Client,
  order: boolean
): Promise<void | OfferHistoryTemplate[] | OrderHistoryTemplate[]> =>
  axiosClient
    .get(PATH_GET_CLIENT_HISTORY(client.id, order), {
      headers,
    })
    .then(
      response =>
        order
          ? (extractOfferTemplates(response.data) as OfferHistoryTemplate[])
          : (extractOrderTemplates(response.data) as OrderHistoryTemplate[]),
      error => printError(error)
    )
    .catch(error => printError(error));

/**
 * Get the clients area meta data
 *
 * @param client
 */
export const getClientAreaMetaData = (
  client: Client
): Promise<void | ClientMetaData> =>
  axiosClient
    .get(PATH_CLIENT_AREA_META_DATA(client.id), {
      headers,
    })
    .then(
      response => extractClientMetaData(response.data),
      error => printError(error)
    )
    .catch(error => printError(error));

export const getHistoryItemData = (
  client: Client,
  order: boolean,
  histroyId: number
): Promise<void | OfferHistoryTemplate | OrderHistoryTemplate> =>
  axiosClient
    .get(PATH_GET_CLIENT_HISTORY_ITEM(client.id, order, histroyId), {
      headers,
    })
    .then(
      response =>
        order
          ? extractOfferTemplate(response.data)
          : extractOrderTemplate(response.data),
      error => printError(error)
    )
    .catch(error => printError(error));

/**
 * Get an isochrone based on a client locations coordinates, range (time)
 * as well as a movement profile.
 *
 * @param profile
 * @param range
 * @param client
 * @param clientLocation
 */
export const getIsochrone = (
  profile: OSRProfiles,
  range: number,
  client: Client,
  clientLocation: ClientLocation
): Promise<any> =>
  axiosClient
    .post(
      `${URL_ORS}${OSR_ISOCHRONES}${profile}`,
      {
        locations: [[clientLocation.lon, clientLocation.lat]],
        range: [range],
        id: `${client.name}_${clientLocation.name}_${moment().format(
          'DDMMYYYY-HHmmSS'
        )}`.replace(/ /g, '_'),
        range_type: 'time',
      },
      { headers: { Authorization: ORS_API_KEY } }
    )
    .then(
      response => response.data,
      error => printError(error)
    );

/**
 * Get a list of all avaiable POIs
 */
export const getPOIs = (): Promise<POI[]> =>
  axiosClient
    .get(PATH_GET_POIS, {
      headers,
      validateStatus: (status: number) => {
        return status < 300;
      },
    })
    .then(
      response =>
        (response.data as []).map(poiItem => {
          const { active, inactive, id, name } = poiItem;
          return { active, inactive, id, name } as POI;
        }),
      error => error
    )
    .catch(error => error);
