import * as React from 'react';

import { Col, Row, Button, Popover } from 'react-bootstrap';
import { ColorResult, SketchPicker } from 'react-color';

import {
  BUTTON_TITLE_ABORT,
  BUTTON_TITLE_ACCEPT,
} from '../../constants/labels';

import { ColorPickerProps, ColorPickerState } from '../../@types/Modal.d';

/**
 * Component to show a color picker and select a color with it.
 */
export default class ColorPicker extends React.Component<
  ColorPickerProps,
  ColorPickerState
> {
  /**
   * Void method to capture the click event.
   *
   * @param event
   */
  static onClickDiv(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
    event.stopPropagation();
  }

  /**
   * Void method to capture the key down event.
   *
   * @param event
   */
  static onKeyDownDiv(event: React.KeyboardEvent<HTMLDivElement>): void {
    event.stopPropagation();
  }

  constructor(props: ColorPickerProps) {
    super(props);

    const { color } = this.props;
    this.state = {
      color,
    };
    this.onClickAccept = this.onClickAccept.bind(this);
    this.onClickAbort = this.onClickAbort.bind(this);
    this.onChangeComplete = this.onChangeComplete.bind(this);
  }

  /**
   * Click action to accept the selected color.
   *
   * @param event
   */
  onClickAccept(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    const { accept, abort } = this.props;
    const { color } = this.state;
    event.stopPropagation();

    accept(color);
    abort();
  }

  /**
   * Click action to abort the color selection.
   *
   * @param event
   */
  onClickAbort(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    const { abort } = this.props;
    event.stopPropagation();

    abort();
  }

  /**
   * Change action when the color selection changes.
   *
   * @param color
   */
  onChangeComplete(color: ColorResult): void {
    this.setState({ color: color.rgb });
  }

  render(): JSX.Element {
    const { color } = this.state;
    const { id } = this.props;

    return (
      <Popover
        id={`colorPicker${id}`}
        placement="auto"
        onClick={ColorPicker.onClickDiv}
      >
        <Popover.Content>
          <Row className="no-gutters">
            <SketchPicker
              color={color}
              onChangeComplete={this.onChangeComplete}
              width="330px"
              presetColors={[]}
            />
          </Row>
          <Row className="no-gutters">
            <Col md={6}>
              <Button
                variant="primary"
                className="ci"
                onClick={this.onClickAccept}
              >
                {BUTTON_TITLE_ACCEPT}
              </Button>
            </Col>
            <Col md={6}>
              <Button
                variant="primary"
                className="ci"
                onClick={this.onClickAbort}
              >
                {BUTTON_TITLE_ABORT}
              </Button>
            </Col>
          </Row>
        </Popover.Content>
      </Popover>
    );
  }
}
