import '../../styles/Map.scss';

import * as React from 'react';

import { forEach, uniq } from 'lodash';
import {
  MapProvider,
  MapComponent as ReactMapComponent,
  mappify,
} from '@terrestris/react-geo';
import { defaults as defaultInteractions, Draw } from 'ol/interaction';
import { transform as projTransform } from 'ol/proj';
import OlMap from 'ol/Map';
import OlView from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import VectorSource from 'ol/source/Vector';
import GeoJSON from 'ol/format/GeoJSON';
import VectorLayer from 'ol/layer/Vector';
import { MapBrowserEvent, Feature, Overlay } from 'ol';
import { FeatureLike } from 'ol/Feature';
import BaseLayer from 'ol/layer/Base';
import {
  defaults as defaultControls,
  Control,
  FullScreen,
  ScaleLine,
} from 'ol/control';
import Point from 'ol/geom/Point';
import Layer from 'ol/layer/Layer';
import OverlayPositioning from 'ol/OverlayPositioning';
import { createEmpty, extend } from 'ol/extent';
import State from 'ol/source/State';
import GeometryType from 'ol/geom/GeometryType';
import { DrawEvent } from 'ol/interaction/Draw';
import Circle from 'ol/geom/Circle';
import { polygon, booleanDisjoint } from '@turf/turf';

import { URL_OSM_SERVER, URL_GEOSERVER } from '../../constants/network';
import {
  LAYER_FIELD_TITLE,
  LAYER_FIELD_TYPE,
  LAYER_TITLE_TILES,
  LAYER_TITLE_LOCATIONS,
  LAYER_TITLE_ISOCHRONE,
  LAYER_TITLE_PERIMETER,
  LAYER_TYPE_DISTRICT,
  COORD_EPSG_3857,
  COORD_EPSG_4326,
  COORD_FREYPLUS,
  FEATURE_FIELD_AREA_KEY,
  FEATURE_FIELD_TYPE,
  FEATURE_FIELD_SELECTED,
  FEATURE_FIELD_AREA_NAME,
  FEATURE_TYPE_LOCATION,
  FEATURE_TYPE_POSTCODE,
  LAYER_TYPE_POSTCODE,
  REQUEST_IDENTIFIER_GET_CLIENT_DATA,
  REQUEST_IDENTIFIER_GET_ISOCHRONE,
  REQUEST_IDENTIFIER_INSERT_META_DATA,
  REQUEST_IDENTIFIER_GET_PERIMETER,
  FEATURE_FIELD_SUBSIDIARY_NAME,
  FEATURE_FIELD_SUBSIDIARY_STREET,
  FEATURE_FIELD_SUBSIDIARY_HOUSENUMBER,
  FEATURE_FIELD_SUBSIDIARY_POSTCODE,
  FEATURE_FIELD_SUBSIDIARY_CITY,
  FEATURE_FIELD_SUBSIDIARY_PLANABLE,
  LAYER_FIELD_COUNTRY_CODE,
  DEFAULT_FEATURE_COLOR_STYLE,
  FEATURE_FIELD_META_INFO,
  MAP_ZOOM,
  MAP_MAX_ZOOM,
  MAP_MIN_ZOOM,
  REQUEST_IDENTIFIER_LOAD_MAP,
  REQUEST_IDENTIFIER_LOAD_TILES,
  STYLE_ZINDEX_BACK,
  STYLE_ZINDEX_FRONT,
} from '../../constants/constants';
import {
  MAP_OVERLAY_CLIENT_LOCATION_NAME,
  MAP_OVERLAY_CLIENT_LOCATION_STREET,
  MAP_OVERLAY_CLIENT_LOCATION_POSTCODE,
  MAP_OVERLAY_CLIENT_LOCATION_PLACE,
  MAP_OVERLAY_AREA_NAME,
  MAP_OVERLAY_POSTCODE,
} from '../../constants/labels';

// eslint-disable-next-line import/no-cycle
import { getAreaStub, applyAreaMetaData } from '../../util/areaUtil';
import { getLocationFeature } from '../../util/featureConversion';
// eslint-disable-next-line import/no-cycle
import { printSelectionPDF } from '../../util/exportUtil';
import {
  generateBaseLayerStyle,
  setFeatureNotSelected,
  setFeatureSelected,
  setAdditionalFeatureSelected,
  setAdditionalFeatureNotSelected,
  setFeatureMultiSelected,
  generatePerimeterStyle,
  rgbTorgba,
} from '../../util/featureStyle';

import config from '../../config';

import {
  ClientLayer,
  ClientLocation,
  ColorStyle,
  Coordinates,
  DynamicPlaningParam,
  PaperSize,
} from '../../@types/Common.d';
import { MapProps, MapState } from '../../@types/Map.d';
import { Area, AreaDescription } from '../../@types/Area.d';

const MappifiedMap = mappify(ReactMapComponent);
export const LAYER_DEFAULT = {
  layerColor: DEFAULT_FEATURE_COLOR_STYLE,
  countryCode: 'de',
  state: '',
  type: LAYER_TYPE_POSTCODE,
  title: config.map.defaultLayer,
} as ClientLayer;

/**
 * Component to show the actual map as well as process interaction with the map.
 * These interactions include selection/deselection of the maps features, as well
 * as creating new features (like new subsidiaries) on the map and many more.
 */
export default class MapComponent extends React.Component<MapProps, MapState> {
  private mapOverlay: Overlay | null = null;

  constructor(props: MapProps) {
    super(props);

    const { client } = this.props;
    this.state = {
      olMap: this.initMap(),
      layersTotal: client ? client.clientLayers.length : 1,
      layersLoaded: new Map<string, ClientLayer>(),
      isDrawing: false,
      markedPermittedFeatures: [],
    };

    this.adjustMapSize = this.adjustMapSize.bind(this);
    this.appendAreaLayerToMap = this.appendAreaLayerToMap.bind(this);
    this.appendAreaLayersToMap = this.appendAreaLayersToMap.bind(this);
    this.appendLocationLayerToMap = this.appendLocationLayerToMap.bind(this);
    this.appendLocationToMap = this.appendLocationToMap.bind(this);
    this.drawPerimeter = this.drawPerimeter.bind(this);
    this.findAreaKeyOnMap = this.findAreaKeyOnMap.bind(this);
    this.findAreaKeysOnMap = this.findAreaKeysOnMap.bind(this);
    this.finishDraw = this.finishDraw.bind(this);
    this.finishLayerLoading = this.finishLayerLoading.bind(this);
    this.fitFeatures = this.fitFeatures.bind(this);
    this.getIntersectingAreas = this.getIntersectingAreas.bind(this);
    this.getLayerColor = this.getLayerColor.bind(this);
    this.getLocationsLayer = this.getLocationsLayer.bind(this);
    this.initMap = this.initMap.bind(this);
    this.insertMetaData = this.insertMetaData.bind(this);
    this.isMarkedArea = this.isMarkedArea.bind(this);
    this.markAreas = this.markAreas.bind(this);
    this.markIsochrone = this.markIsochrone.bind(this);
    this.markMutliSelectedArea = this.markMutliSelectedArea.bind(this);
    this.markSelectedAreas = this.markSelectedAreas.bind(this);
    this.onClickMap = this.onClickMap.bind(this);
    this.printSelection = this.printSelection.bind(this);
    this.removeDraw = this.removeDraw.bind(this);
    this.removeLocationFromMap = this.removeLocationFromMap.bind(this);
    this.removeMarkedFeatures = this.removeMarkedFeatures.bind(this);
    this.setIsDrawing = this.setIsDrawing.bind(this);
    this.setSubsidiarySelected = this.setSubsidiarySelected.bind(this);
    this.showToolTip = this.showToolTip.bind(this);
    this.toggleIsDrawing = this.toggleIsDrawing.bind(this);
    this.zoomToCoordinates = this.zoomToCoordinates.bind(this);
    this.zoomToMarker = this.zoomToMarker.bind(this);

    this.appendAreaLayerToMap(LAYER_DEFAULT);
  }

  /**
   * This method is used to determine changes if the components props changed.
   * For example if the client is changed.
   *
   * @param prevProps
   */
  componentDidUpdate(prevProps: MapProps): void {
    const { olMap } = this.state;
    const { client } = this.props;

    if (client !== prevProps.client) {
      // The previous clients layers can now be removed
      olMap.getLayers().forEach(layer => {
        if (layer instanceof VectorLayer) {
          olMap.removeLayer(layer);
        }
      });

      if (client) {
        // If the new client is not falsely, start loading the new client layers etc.
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState(
          {
            layersTotal: client.clientLayers.length,
            layersLoaded: new Map<string, ClientLayer>(),
          },
          () => {
            this.appendAreaLayersToMap(client.clientLayers);
            this.appendLocationLayerToMap(client.clientLocations);
          }
        );
      } else {
        // If the client is falsely, load the default layer
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState(
          {
            layersTotal: 1,
          },
          () => {
            this.appendAreaLayerToMap(LAYER_DEFAULT);
          }
        );
      }
    }
  }

  /**
   * Processes click actions on the map.
   *
   * @param event
   */
  onClickMap(event: MapBrowserEvent): void {
    const { olMap } = this.state;
    const { lockSelection, weekpart, client } = this.props;
    // Check every feature at the position of the click event
    olMap.forEachFeatureAtPixel(
      event.pixel,
      (feature: FeatureLike, layer: Layer) => {
        // If the features layer is no vector layer, skip.
        // The other cases would be e.g. the maps tile layer, which aren't relevant
        // for the selection process
        if (layer instanceof VectorLayer && feature instanceof Feature) {
          // If the features type is not equal to LOCATION it is a prostcode or district are
          // which can be selected
          if (feature.get(FEATURE_FIELD_TYPE) !== FEATURE_TYPE_LOCATION) {
            // If the feature is already selected, deselect it.
            if (feature.get(FEATURE_FIELD_SELECTED)) {
              const {
                removeArea,
                isSelectedByCurrentSubsidiary,
                subsidiaryMode,
                addArea,
                addRemoveAreaRestrictedPlanning,
              } = this.props;

              const selectedArea = getAreaStub({
                areaKey: feature.get(FEATURE_FIELD_AREA_KEY),
                countryCode: layer.get(LAYER_FIELD_COUNTRY_CODE),
                type: layer.get(LAYER_FIELD_TYPE),
                weekpart,
              } as AreaDescription);

              // If the client has a planning res
              if (client?.planningRestriction === 'TEMPLATE') {
                addRemoveAreaRestrictedPlanning(selectedArea, true);
              }
              // If the feature is selected by the current subsidiary or this is a district
              // or there are no subsidiaries, deselect the feature
              else if (!lockSelection) {
                if (
                  isSelectedByCurrentSubsidiary(
                    feature.get(FEATURE_FIELD_AREA_KEY)
                  ) ||
                  layer.get(LAYER_FIELD_TYPE) === LAYER_TYPE_DISTRICT ||
                  !subsidiaryMode
                ) {
                  removeArea(selectedArea);
                }
                // Otherwise add the area and later split its localities between the subsidiaries
                else {
                  addArea({
                    areaKey: feature.get(FEATURE_FIELD_AREA_KEY),
                    countryCode: layer.get(LAYER_FIELD_COUNTRY_CODE),
                    type: layer.get(LAYER_FIELD_TYPE),
                    weekpart,
                  } as AreaDescription);
                }
              }
              // If the feature is not yet selected, select it.
            } else {
              const selectedArea = {
                areaKey: feature.get(FEATURE_FIELD_AREA_KEY),
                countryCode: layer.get(LAYER_FIELD_COUNTRY_CODE),
                type: layer.get(LAYER_FIELD_TYPE),
                weekpart,
              } as AreaDescription;

              const { addArea, addRemoveAreaRestrictedPlanning } = this.props;

              if (client?.planningRestriction === 'TEMPLATE')
                addRemoveAreaRestrictedPlanning(selectedArea, false);
              else addArea(selectedArea);
            }

            // To abort the iteration, a feature has to be returned.
            // Otherwise all features on the clicks pixel would be iterated
            // which can e.g. cause the selection of a subsidiary and an area
            // with one click (which is not intended).
            return feature;
          }
          // If the features type is LOCATION, select the location as currently
          // selected subsidiary.
          if (feature.get(FEATURE_FIELD_TYPE) === FEATURE_TYPE_LOCATION) {
            // If this subsidiary is no declared as planable, just set the map's position to it.
            if (
              feature.get(FEATURE_FIELD_SUBSIDIARY_PLANABLE) &&
              client?.planningRestriction !== 'TEMPLATE'
            )
              this.setSubsidiarySelected(feature);
            else this.zoomToMarker(feature);
            // reason: see above
            return feature;
          }
        }

        // return something so eslint doesn#t complain
        return true;
      }
    );
  }

  /**
   * Returns the colors of a given layer.
   *
   * @param layer
   */
  getLayerColor(layer: VectorLayer | undefined): ColorStyle {
    if (layer) {
      const { layersLoaded } = this.state;
      const loadedLayer = layersLoaded.get(layer.get(LAYER_FIELD_TITLE));
      if (loadedLayer) return loadedLayer.layerColor;
    }
    return {
      fill: 'rgba(0, 0, 0, 0)',
      fillSelected: 'rgba(113, 178, 255, 0.2)',
      stroke: 'rgba(113, 178, 255, 1.0)',
      strokeSelected: 'rgba(113, 178, 255, 1.0)',
      zIndex: STYLE_ZINDEX_BACK,
    } as ColorStyle;
  }

  /**
   * Returns the map layer which contains the clients subsidiaries
   */
  getLocationsLayer(): BaseLayer | undefined {
    const { olMap } = this.state;

    return olMap
      .getLayers()
      .getArray()
      .find(sLayer => {
        if (sLayer instanceof VectorLayer)
          return sLayer.get(LAYER_FIELD_TITLE) === LAYER_TITLE_LOCATIONS;
        return false;
      });
  }

  /**
   * Returns all areas the intersect with an array of given features.
   * This method is used for perimeter selection and isochone/dynamic
   * planing.
   *
   * @param intersectionArea
   */
  getIntersectingAreas(intersectionArea: Feature[]): AreaDescription[] {
    const intersectionAreaExtent = intersectionArea[0]
      .getGeometry()
      ?.getExtent();

    // if the extend of the given features is empty, skip
    if (!intersectionAreaExtent) return [] as AreaDescription[];

    const { weekpart } = this.props;
    const { olMap } = this.state;

    // We need a geojson object to conevert the data from the api
    // to an openlayers complatible form.
    const geoJSON = new GeoJSON({
      dataProjection: COORD_EPSG_4326,
      featureProjection: COORD_EPSG_3857,
    });

    const intersectingFeatures = [] as AreaDescription[];

    const intersectionAreaObject = geoJSON.writeFeatureObject(
      intersectionArea[0]
    );

    // Check each of the map's vector layers for intersecting features
    olMap.getLayers().forEach(layer => {
      // Only check the normal area layers
      if (
        !(layer instanceof VectorLayer) ||
        layer.get(LAYER_FIELD_TITLE) === LAYER_TITLE_LOCATIONS ||
        layer.get(LAYER_FIELD_TITLE) === LAYER_TITLE_ISOCHRONE
      )
        return true;

      layer
        .getSource()
        .forEachFeatureIntersectingExtent(
          intersectionAreaExtent,
          (feature: Feature) => {
            // Add each intersecting feature if the layer to the array
            // of intersecting features
            const featureObject = geoJSON.writeFeatureObject(feature);
            const countryCode = layer.get(LAYER_FIELD_COUNTRY_CODE);
            const type = layer.get(LAYER_FIELD_TYPE);

            // Here we need to check if the feature consists of one or more
            // distinct forms. This is important for the coneversion of the feature
            // so it's usable for the turf.js library.
            let parts;
            if (featureObject.geometry.type === 'MultiPolygon')
              parts = featureObject.geometry.coordinates.map((coord: any) =>
                polygon(coord)
              );
            else if (featureObject.geometry.type === 'Polygon')
              parts = [polygon(featureObject.geometry.coordinates)];

            if (parts)
              parts.forEach(poly => {
                if (!booleanDisjoint(intersectionAreaObject, poly))
                  intersectingFeatures.push({
                    areaKey: feature.get(FEATURE_FIELD_AREA_KEY),
                    weekpart,
                    countryCode,
                    type,
                  });
              });
          }
        );

      return true;
    });

    // Return the intersecting features
    return intersectingFeatures;
  }

  /**
   * Method to select a subsidiary feature from the map as
   * the new selected subsidiary.
   *
   * @param feature
   */
  setSubsidiarySelected(feature: Feature): void {
    const { selectSubsidiary } = this.props;

    selectSubsidiary(feature);
  }

  /**
   * Enables the drawing mode on the map.
   *
   * @param isDrawing
   */
  setIsDrawing(isDrawing: boolean): boolean {
    this.setState({ isDrawing });

    return isDrawing;
  }

  /**
   * Method to let map know it's size has changed.
   * This is mainly used when the map slide-in lists
   * are slid in or out.
   * The delay provides the security that the list is
   * fully expanded before the map adjusts. Otherwise
   * the maps height/width ratio wouldn't be correct.
   */
  adjustMapSize(): void {
    const { olMap } = this.state;

    setTimeout(() => {
      olMap.updateSize();
    }, 700);
  }

  /**
   * Append an array of layers to the map.
   *
   * @param layers
   */
  appendAreaLayersToMap(layers: ClientLayer[]): void {
    layers.forEach(layer => {
      this.appendAreaLayerToMap(layer);
    });
  }

  /**
   * Append a layer to the map.
   *
   * @param layers
   */
  appendAreaLayerToMap(layer: ClientLayer): void {
    const { olMap } = this.state;

    // Instantiate a new Vector Source
    const vectorSource = new VectorSource({
      url: URL_GEOSERVER(layer.title),
      format: new GeoJSON(),
      overlaps: false,
    });

    // Instantiate a new Vector Layer with the layers styling
    const vectorLayer = new VectorLayer({
      visible: true,
      source: vectorSource,
      style: feature => generateBaseLayerStyle(feature, layer.layerColor),
    });

    // Add the layer information
    vectorLayer.set(LAYER_FIELD_TITLE, layer.title);
    vectorLayer.set(LAYER_FIELD_TYPE, layer.type);
    vectorLayer.set(LAYER_FIELD_COUNTRY_CODE, layer.countryCode);

    // Add a listener so it can determined wheather the layer has fully loaded
    const listener = vectorSource.on('change', () => {
      // If the sources state is READY, all features have been read
      if (vectorSource.getState() === State.READY) {
        const { layersLoaded } = this.state;
        // Add the layer to the layersLoaded set
        layersLoaded.set(layer.title, layer);
        const lLayer = layersLoaded.get(layer.title);
        // If the layer hasn't been marked as loaded, mark it as loaded
        if (lLayer && !lLayer.loaded) {
          lLayer.loaded = true;
          layersLoaded.set(layer.title, lLayer);
          // Update the state
          this.setState({ layersLoaded }, () => this.finishLayerLoading());
        }

        // Remove the listener
        vectorSource.un('change', listener as any);
      }
    });

    // Finally add the layer to the map
    olMap.addLayer(vectorLayer);
  }

  /**
   * Method to finish the loading of the clients layers
   */
  finishLayerLoading(): void {
    const { enableLoadingOverlay, client, addInitAreas } = this.props;
    const { layersTotal, layersLoaded } = this.state;

    // If the amount of loaded layers is equal to the numbers of the clients layers
    // continue with the maps initiation.
    if (
      Array.from(layersLoaded.values()).filter(val => val.loaded).length ===
      layersTotal
    ) {
      if (client?.clientMetaData?.areaMetaData) this.insertMetaData(true);

      enableLoadingOverlay(false, REQUEST_IDENTIFIER_GET_CLIENT_DATA);
      addInitAreas();
    }
  }

  /**
   * Append a layer of the clients subsidiaries to the map.
   *
   * @param locations
   */
  appendLocationLayerToMap(locations: ClientLocation[]): void {
    const { olMap } = this.state;

    const locationLayer = new VectorLayer({
      visible: true,
      source: new VectorSource({
        features: [],
      }),
    });
    locationLayer.set('title', LAYER_TITLE_LOCATIONS);

    olMap.addLayer(locationLayer);

    // Append the clients subsidiaries to  the map
    locations.forEach(location => this.appendLocationToMap(location));
  }

  /**
   * Appends a clients subsdiary to the map
   * @param location
   */
  appendLocationToMap(location: ClientLocation): void {
    const layer = this.getLocationsLayer();

    // If there is no layer for the subsidiaries abort
    if (!layer) return;
    // If the locations layer is a vector layer add the location to the map
    if (layer instanceof VectorLayer) {
      // Make a locations feature with the subsidiaties information
      const layerFeature = getLocationFeature(location, false);

      // Add a reference to the feature and layer to prevent searching for
      // the feature later.
      location.layer = layer;
      location.feature = layerFeature;

      // Add the subsdiary to the map
      layer.getSource().addFeature(layerFeature);
    }
  }

  /**
   * Removes a single subsidiary from the map.
   *
   * @param location
   */
  removeLocationFromMap(location: ClientLocation): void {
    const layer = this.getLocationsLayer() as VectorLayer;

    if (location.feature?.get(FEATURE_FIELD_TYPE) === FEATURE_TYPE_LOCATION)
      layer.getSource().removeFeature(location.feature);
  }

  /**
   * Find features for a given array of areaKey (postcode or district id)
   * on the map and return the feature as well as the according layer.
   *
   * @param areaKeys
   */
  findAreaKeysOnMap(
    areaKeys: string[]
  ): { feature?: Feature; layer?: VectorLayer }[] {
    return areaKeys
      .map(areaKey => this.findAreaKeyOnMap(areaKey))
      .filter(area => area);
  }

  /**
   * Find a feature for a given areaKey (postcode or district id)
   * on the map and return the feature as well as the according layer.
   *
   * @param areaKeys
   */
  findAreaKeyOnMap(
    areaKey: string
  ): { feature?: Feature; layer?: VectorLayer } {
    const { olMap } = this.state;
    let foundFeature: Feature | undefined;
    let foundLayer: VectorLayer | undefined;

    olMap.getLayers().forEach((layer: any) => {
      if (layer instanceof VectorLayer && !foundFeature) {
        forEach(layer.getSource().getFeatures(), feature => {
          if (feature.get(FEATURE_FIELD_AREA_KEY) === areaKey) {
            foundFeature = feature;
            foundLayer = layer;
            return false;
          }
          return true;
        });
      }
    });

    return { feature: foundFeature, layer: foundLayer };
  }

  /**
   * Initiate the map
   */
  initMap(): OlMap {
    const { enableLoadingOverlay } = this.props;

    // Enable loading animation
    enableLoadingOverlay(true, REQUEST_IDENTIFIER_LOAD_MAP);
    enableLoadingOverlay(true, REQUEST_IDENTIFIER_LOAD_TILES);

    // Create a new tile layer (layer that displays the map)
    const tileLayer = new TileLayer({
      source: new OSM({
        url: URL_OSM_SERVER,
        crossOrigin: 'anonymous',
      }),
    });
    // Add infotmation to the layer
    tileLayer.set(LAYER_FIELD_TITLE, LAYER_TITLE_TILES);
    tileLayer.on('postrender', () =>
      // disable loading after map hs loaded
      enableLoadingOverlay(false, REQUEST_IDENTIFIER_LOAD_TILES)
    );

    // Generate desfault controls
    const controls = defaultControls();

    // Add custom controls to the map
    const customControls = [] as Control[];
    customControls.push(new ScaleLine());
    if (config.map.buttons.fullscreen) {
      customControls.push(new FullScreen({ source: 'mapFullscreenContainer' }));
    }

    controls.extend(customControls);

    // Instantiate the map
    const olMap = new OlMap({
      controls,
      interactions: defaultInteractions({ doubleClickZoom: false }),
      view: new OlView({
        center: projTransform(COORD_FREYPLUS, COORD_EPSG_4326, COORD_EPSG_3857),
        zoom: MAP_ZOOM,
        maxZoom: MAP_MAX_ZOOM,
        minZoom: MAP_MIN_ZOOM,
      }),
      layers: [tileLayer],
    });

    // Add click event for selection
    olMap.on('click', event => this.onClickMap(event));
    // Add pointer move event for tooltips
    olMap.on('pointermove', event => this.showToolTip(event));
    // Once the map has rendered add an overlay for the tooltip and finish loading
    olMap.once('postrender', () => {
      const popup = document.getElementById('popup');
      if (popup !== null) {
        this.mapOverlay = new Overlay({
          id: 'overlay',
          element: popup,
          autoPan: false,
          offset: [-3, -3],
          positioning: OverlayPositioning.BOTTOM_RIGHT,
        });

        olMap.addOverlay(this.mapOverlay);
      }

      olMap.updateSize();

      enableLoadingOverlay(false, REQUEST_IDENTIFIER_LOAD_MAP);
    });

    // Add fullscreen listener
    document.addEventListener('fullscreenchange', () => {
      const { changeFullscreen } = this.props;
      changeFullscreen(document.fullscreenElement !== null);

      olMap.updateSize();
    });

    return olMap;
  }

  /**
   * Check if a given feature contains meta info and/or special coloring
   *
   * @param feature
   */
  isMarkedArea(feature: Feature): ColorStyle | undefined {
    const { client } = this.props;

    if (!client?.clientMetaData) return undefined;

    const { areaMetaData } = client.clientMetaData;

    return areaMetaData?.find(
      meta => meta.areaKey === feature.get(FEATURE_FIELD_AREA_KEY)
    )?.areaStyle;
  }

  /**
   * Insert meta data and special coloring into corresponding features
   *
   * @param layersLoaded
   */
  insertMetaData(layersLoaded?: boolean): void {
    // check if layers all hace loaded so the feature is there
    if (!layersLoaded) return;

    const { enableLoadingOverlay, client } = this.props;

    if (!client) return;

    enableLoadingOverlay(true, REQUEST_IDENTIFIER_INSERT_META_DATA);
    const { clientMetaData } = client;
    const { areaMetaData } = clientMetaData;

    // Search for the feature and insert the correct meta data
    this.findAreaKeysOnMap(
      areaMetaData.map(areaMeta => areaMeta.areaKey)
    ).forEach(({ feature }) =>
      applyAreaMetaData(
        feature,
        areaMetaData.find(
          meta => meta.areaKey === feature?.get(FEATURE_FIELD_AREA_KEY) ?? ''
        )
      )
    );

    enableLoadingOverlay(false, REQUEST_IDENTIFIER_INSERT_META_DATA);
  }

  /**
   * Show a tooltip with information about the feature on mouseover
   *
   * @param event
   */
  showToolTip(event: MapBrowserEvent): void {
    const { olMap } = this.state;
    const feature = olMap.forEachFeatureAtPixel(
      event.pixel,
      pFeature => pFeature
    );
    if (this.mapOverlay === null) return;
    // Only show tool tip if the feature is a subsidiary or
    // the feature has meta info
    if (
      !feature ||
      !(feature instanceof Feature) ||
      (!feature.get(FEATURE_FIELD_META_INFO) &&
        feature.get(FEATURE_FIELD_TYPE) !== FEATURE_TYPE_LOCATION)
    ) {
      // If there shouldn't be a tooltip hide it
      this.mapOverlay.setPosition(undefined);
      return;
    }

    // Set the position of the tooltip to the pointer's position
    this.mapOverlay.setPosition(event.coordinate);

    const mapOverlayContent = document.getElementById('popup-content');

    if (mapOverlayContent === null) return;

    // Generate a tooltip
    if (feature.get(FEATURE_FIELD_TYPE) === FEATURE_TYPE_LOCATION) {
      mapOverlayContent.innerHTML = `<b>${MAP_OVERLAY_CLIENT_LOCATION_NAME}:</b>
       ${feature.get(FEATURE_FIELD_SUBSIDIARY_NAME)}
      <br>
      <b>${MAP_OVERLAY_CLIENT_LOCATION_STREET}:</b>
       ${feature.get(FEATURE_FIELD_SUBSIDIARY_STREET)}
       ${feature.get(FEATURE_FIELD_SUBSIDIARY_HOUSENUMBER)}
      <br>
      <b>${MAP_OVERLAY_CLIENT_LOCATION_POSTCODE}/${MAP_OVERLAY_CLIENT_LOCATION_PLACE}:</b>
       ${feature.get(FEATURE_FIELD_SUBSIDIARY_POSTCODE)}
       ${feature.get(FEATURE_FIELD_SUBSIDIARY_CITY)}
      <br>`;
    } else if (
      feature.get(FEATURE_FIELD_TYPE) === FEATURE_TYPE_POSTCODE &&
      feature.get(FEATURE_FIELD_META_INFO)
    ) {
      const meta = feature.get(FEATURE_FIELD_META_INFO);

      mapOverlayContent.innerHTML = `<b>${MAP_OVERLAY_AREA_NAME}:</b>
             ${feature.get(FEATURE_FIELD_AREA_NAME)}
            <br>
            <b>${MAP_OVERLAY_POSTCODE}:</b>
             ${feature.get(FEATURE_FIELD_AREA_KEY)}
            <br>
            ${Object.keys(meta)
              .map(
                (key: string) => `<b>${key}:</b>
             ${meta[key]}
            <br>`
              )
              .join('')}`;
    }
  }

  /**
   * Mark a feature as selected
   *
   * @param areas
   * @param selected
   * @param show
   * @param subsidiaryColor
   */
  markSelectedAreas(
    areas: Area[],
    selected: boolean,
    show: boolean = true,
    subsidiaryColor?: string
  ): void {
    const { getMultiSelectionSubsidiaries } = this.props;

    this.markAreas(areas, selected, show, subsidiaryColor, false);

    forEach(areas, area => {
      // Check if the feature/area is already selected by another subsidiary
      const multiSelectSubsidiairies = getMultiSelectionSubsidiaries(
        area.areaKey
      );

      // If this is the case continue with the multiselection process
      if (multiSelectSubsidiairies.length > 1) {
        this.markMutliSelectedArea(area, multiSelectSubsidiairies);
        // Else just set the feature selected
      } else {
        const { feature } = area;

        if (!feature) return true;

        feature.set(FEATURE_FIELD_SELECTED, selected);
      }
      // If the area has additional areas also mark those
      if (area.additionalAreas.length > 0) {
        this.markAreas(
          area.additionalAreas,
          selected,
          show,
          subsidiaryColor,
          true
        );
      }

      return true;
    });
  }

  /**
   * Sets the coloring of areas accordingly
   *
   * @param areas
   * @param selected
   * @param show
   * @param subsidiaryColor
   * @param isAdditionalAreas
   */
  markAreas(
    areas: Area[],
    selected: boolean,
    show: boolean = true,
    pSubsidiaryColor?: string,
    isAdditionalAreas: boolean = false
  ): void {
    const { markedPermittedFeatures } = this.state;
    const { client, selectedDistributionTemplate } = this.props;
    let subsidiaryColor = pSubsidiaryColor;
    let newMarkedPermittedFeatures = markedPermittedFeatures;

    areas.forEach(area => {
      const { feature, layer } = area;
      if (feature) {
        // Get the layer coloring
        const colors = { ...this.getLayerColor(layer) };

        // If the area is selected by a subsidiary use those colors instead
        if (subsidiaryColor) {
          colors.fillSelected = subsidiaryColor;
        }

        if (
          client?.planningRestriction === 'TEMPLATE' &&
          selectedDistributionTemplate?.locations
            .find(location => location.locationId === area.subsidiaryId)
            ?.areas.find(pArea => pArea.areaKey === area.areaKey)
        ) {
          if (!subsidiaryColor)
            subsidiaryColor = client.clientLocations.find(
              clientLocation => clientLocation.id === area.subsidiaryId
            )?.colorSelectedFill;

          if (subsidiaryColor) {
            colors.stroke = rgbTorgba(subsidiaryColor, 1);
            colors.strokeSelected = rgbTorgba(subsidiaryColor, 1);
            colors.zIndex = STYLE_ZINDEX_FRONT;
            colors.strokeWidth = 2;
          }

          newMarkedPermittedFeatures = [
            ...newMarkedPermittedFeatures,
            ...[feature],
          ].filter(
            (fFeature, index, features) =>
              index ===
                features.findIndex(
                  pFeature => pFeature?.getId() === fFeature?.getId()
                ) && fFeature
          );
        }

        // If the feature is selected and visible mark it as selected
        if (selected && show) {
          // If these are additional areas apply the additional color style
          if (isAdditionalAreas) setAdditionalFeatureSelected(feature, colors);
          // Else set the normal selected style
          else setFeatureSelected(feature, colors);
          // If the feature is not selected and an additional area, check if the
          // layer has special coloring and apply the not selected style
        } else if (isAdditionalAreas)
          setAdditionalFeatureNotSelected(feature, this.isMarkedArea(feature));
        else if (client?.planningRestriction === 'TEMPLATE' && show)
          setFeatureNotSelected(feature, colors);
        // If the feature is not selected, check if the
        // layer has special coloring and apply the not selected style
        else setFeatureNotSelected(feature, this.isMarkedArea(feature));
      }
    });

    this.setState({
      markedPermittedFeatures: newMarkedPermittedFeatures,
    });
  }

  /**
   * Marks a feature as selected by multiple subsdiaries
   *
   * @param area
   * @param subsidiaries
   */
  markMutliSelectedArea(area: Area, subsidiaries: ClientLocation[]): void {
    const { feature, layer } = area;

    if (!feature || !layer) return;

    // Get the layers coloring
    const layerColor = this.getLayerColor(layer);
    // Check if any of the subsidiaries is set to visible
    const show = subsidiaries.some(subsidiary => subsidiary.selected);

    // Get alle subsidiary colors
    const colors = subsidiaries.map(subsidiary => ({
      ...(layerColor ??
        ({
          fill: 'rgba(0, 0, 0, 0)',
          fillSelected: 'rgba(113, 178, 255, 0.2)',
          stroke: 'rgba(113, 178, 255, 1.0)',
          strokeSelected: 'rgba(113, 178, 255, 1.0)',
          zIndex: STYLE_ZINDEX_BACK,
        } as ColorStyle)),
      ...{ fillSelected: subsidiary.colorSelectedFill },
    }));

    // Apply the multi selection style to the feature
    if (show) setFeatureMultiSelected(feature, colors);
    // Else mark it as unselected
    else setFeatureNotSelected(feature, this.isMarkedArea(feature));
  }

  removeMarkedFeatures(): void {
    const { markedPermittedFeatures } = this.state;

    [...markedPermittedFeatures].forEach(markedFeaure =>
      setFeatureNotSelected(markedFeaure, this.isMarkedArea(markedFeaure))
    );

    this.setState({ markedPermittedFeatures: [] });
  }

  /**
   * Toggle the drawing mode
   */
  toggleIsDrawing(): boolean {
    const { isDrawing } = this.state;

    return this.setIsDrawing(!isDrawing);
  }

  /**
   * Draw a perimeter for perimeter selection
   */
  drawPerimeter(): void {
    const { olMap } = this.state;

    // If drawing is not enabled remove eventually existing
    // drawing and return
    if (!this.toggleIsDrawing()) {
      this.removeDraw();

      return;
    }

    // Create a new source and layer for the perimeter
    const perimeterSource = new VectorSource({ wrapX: false });
    const perimeterLayer = new VectorLayer({
      source: perimeterSource,
    });

    // Set layer information
    perimeterLayer.set(LAYER_FIELD_TITLE, LAYER_TITLE_PERIMETER);

    // Create a draw interaction
    const drawInteractionNew = new Draw({
      source: perimeterSource,
      type: GeometryType.CIRCLE,
      style: generatePerimeterStyle(),
    });

    // Add event listeners to the interaction
    drawInteractionNew.once('drawstart', (event: DrawEvent) =>
      event.feature.getGeometry().on('change', () =>
        event.feature.setStyle(
          generatePerimeterStyle(
            (
              (event.feature.getGeometry() as Circle).getRadius() / 1000
            ).toLocaleString('en-de', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          )
        )
      )
    );
    drawInteractionNew.once('drawend', (event: DrawEvent) =>
      this.finishDraw(event)
    );
    drawInteractionNew.once('drawabort', this.removeDraw);

    this.setState(
      {
        drawInteraction: drawInteractionNew,
      },
      () => {
        olMap.addLayer(perimeterLayer);

        olMap.addInteraction(drawInteractionNew);
      }
    );
  }

  /**
   * Rewmove drawings from the map
   */
  removeDraw(): void {
    const { olMap, drawInteraction } = this.state;

    if (drawInteraction) olMap.removeInteraction(drawInteraction);
    this.setIsDrawing(false);
    this.setState(
      {
        drawInteraction: undefined,
      },
      () => {
        olMap.getLayers().forEach(layer => {
          if (layer.get(LAYER_FIELD_TITLE) === LAYER_TITLE_PERIMETER)
            olMap.removeLayer(layer);
        });
      }
    );
  }

  /**
   * Finish drawing the perimeter and add intersecting areas
   * to the selection.
   *
   * @param event
   */
  finishDraw(event: DrawEvent): void {
    const { enableLoadingOverlay, addPerimeterAreas } = this.props;
    enableLoadingOverlay(true, REQUEST_IDENTIFIER_GET_PERIMETER);

    // Get all areas intersecting with the drawn perimeter
    const intersectingAreas = this.getIntersectingAreas([event.feature]);

    // Remove the drawing
    this.removeDraw();
    // Save the intersecting areas, excluding all previously selected areas
    // This enables us to revert the preimeter selection
    addPerimeterAreas(intersectingAreas);

    enableLoadingOverlay(false, REQUEST_IDENTIFIER_GET_PERIMETER);
  }

  /**
   * Add features from dynamic/isochrone plaing to selection
   *
   * @param isochrone
   * @param dynamicPlaningParams
   */
  markIsochrone(
    isochrone: any,
    dynamicPlaningParams: DynamicPlaningParam[]
  ): void {
    const { getDynamicAreas, enableLoadingOverlay } = this.props;
    const { olMap } = this.state;

    // Instantiate a GeoJSON object to get a compatible version of the isochrone
    const geoJSON = new GeoJSON({
      dataProjection: COORD_EPSG_4326,
      featureProjection: COORD_EPSG_3857,
    });
    const isochroneFeatures = geoJSON.readFeatures(isochrone);

    // If enabled draw the isochrone on the map
    if (config.general.showIsochrone) {
      const isochroneSource = new VectorSource({
        features: isochroneFeatures,
        overlaps: false,
      });

      // TODO variable layercolor
      const isochroneLayer = new VectorLayer({
        visible: true,
        source: isochroneSource,
        style: feature =>
          generateBaseLayerStyle(feature, {
            fill: 'rgba(255, 0, 0, 0)',
            fillSelected: 'rgba(255, 0, 0, 0)',
            stroke: 'rgba(255, 0, 0, 1.0)',
            strokeSelected: 'rgba(255, 0, 0, 1.0)',
            strokeWidth: 5,
            zIndex: STYLE_ZINDEX_FRONT,
          } as ColorStyle),
      });
      isochroneLayer.set(LAYER_FIELD_TITLE, LAYER_TITLE_ISOCHRONE);

      olMap.addLayer(isochroneLayer);
    }

    // Get the intersecting features
    const intersectingFeatures = this.getIntersectingAreas(isochroneFeatures);

    enableLoadingOverlay(false, REQUEST_IDENTIFIER_GET_ISOCHRONE);
    // Add the intersecting features to the selection
    getDynamicAreas(uniq(intersectingFeatures), dynamicPlaningParams);
  }

  /**
   * Fits the visible map to the selected features
   *
   * @param features
   */
  fitFeatures(features: Feature[]): void {
    const { olMap } = this.state;

    if (!features || features.length <= 0) return;

    // Get the extend of the features you wish to fit
    const featuresExtent = features.reduce((acc, feature) => {
      if (feature) {
        const geometry = feature.getGeometry();
        if (geometry) {
          let accExtend = acc;
          accExtend = extend(accExtend, geometry.getExtent());
          return accExtend;
        }
      }

      return acc;
    }, createEmpty());

    olMap.getView().fit(featuresExtent, {
      padding: [100, 100, 100, 100],
      maxZoom: MAP_ZOOM,
    });
  }

  /**
   * Generates a pdf with and image of the current selection
   * @param features
   */
  printSelection(
    features: Feature[],
    paperSize: PaperSize,
    selectedResolution: number
  ): void {
    const { olMap } = this.state;
    const { enableLoadingOverlay } = this.props;

    printSelectionPDF(
      features,
      olMap,
      paperSize,
      selectedResolution,
      enableLoadingOverlay
    );
  }

  /**
   * Adjust the visible map to a feature (usualy a subsdiary marker)
   *
   * @param feature
   */
  zoomToMarker(feature: Feature): void {
    const { olMap } = this.state;
    const geometry = feature.getGeometry();
    if (geometry) {
      const featurePoint = geometry as Point;
      olMap
        .getView()
        .animate({ center: featurePoint.getCoordinates(), zoom: MAP_ZOOM });
    }
  }

  /**
   * Adjust the visible map to given coordinates
   *
   * @param coordinates
   */
  zoomToCoordinates(coordinates: Coordinates): void {
    const { olMap } = this.state;
    olMap.getView().animate({
      center: projTransform(
        [coordinates.lon, coordinates.lat],
        COORD_EPSG_4326,
        COORD_EPSG_3857
      ),
      zoom: MAP_ZOOM,
    });
  }

  render(): JSX.Element {
    const { olMap } = this.state;

    return (
      <MapProvider map={olMap}>
        <div id="popup" className="ol-popup">
          <div id="popup-content" className="popup-content" />
        </div>
        <MappifiedMap className="h-100" />
      </MapProvider>
    );
  }
}
