import * as React from 'react';

import { Modal, Button, Col, Form } from 'react-bootstrap';

import {
  BUTTON_TITLE_CREATE,
  BUTTON_TITLE_ABORT,
  MODAL_TITLE_ADD_DISTRIBUTION_TEMPLATE,
  DISTRIBUTION_TEMPLATE_MODAL_NAME_TITLE,
  DISTRIBUTION_TEMPLATE_MODAL_NAME_PLACEHOLDER,
  DISTRIBUTION_TEMPLATE_MODAL_NAME_INVALID,
  MODAL_TITLE_UPDATE_DISTRIBUTION_TEMPLATE,
  BUTTON_TITLE_SUBMIT,
  DISTRIBUTION_TEMPLATE_MODAL_UPDATE_AREAS_CHECK,
} from '../../constants/labels';

import {
  DistributionTemplateModalProps,
  DistributionTemplateModalState,
} from '../../@types/Modal.d';
import { ClientLocation, DistributionTemplate } from '../../@types/Common.d';

/**
 * Modal template to create a new distribution template from the current selection
 * or the current selection of a specific subsidiary.
 */
export default class DistributionTemplateModal extends React.Component<
  DistributionTemplateModalProps,
  DistributionTemplateModalState
> {
  constructor(props: DistributionTemplateModalProps) {
    super(props);
    this.state = {
      name: '',
      validated: false,
      updateAreasCheck: false,
    };

    this.onChangeName = this.onChangeName.bind(this);
    this.onClickSubmit = this.onClickSubmit.bind(this);
    this.onChangeUpdateAreas = this.onChangeUpdateAreas.bind(this);
    this.onHide = this.onHide.bind(this);
    this.setSubsidiary = this.setSubsidiary.bind(this);
    this.setDistributionTemplate = this.setDistributionTemplate.bind(this);
  }

  /**
   * Change action for the distribution templates name.
   *
   * @param event
   */
  onChangeName(event: React.ChangeEvent<HTMLInputElement>): void {
    event.stopPropagation();
    this.setState({
      name: event.currentTarget.value,
    });
  }

  /**
   * Click action to submit the new distribution template.
   *
   * @param event
   */
  onClickSubmit(event: React.FormEvent<HTMLFormElement>): void {
    const formValid = event.currentTarget.checkValidity();

    event.preventDefault();
    event.stopPropagation();

    this.setState({ validated: true }, () => {
      if (formValid) {
        const {
          newDistributionTemplate,
          updateistributionTemplate,
        } = this.props;
        const {
          name,
          subsidiary,
          distributionTemplate,
          updateAreasCheck,
        } = this.state;

        if (distributionTemplate)
          updateistributionTemplate(
            distributionTemplate,
            name,
            subsidiary,
            updateAreasCheck
          );
        else newDistributionTemplate(name, subsidiary);

        this.onHide();
      }
    });
  }

  onChangeUpdateAreas(event: React.ChangeEvent<HTMLInputElement>): void {
    event.stopPropagation();

    this.setState({ updateAreasCheck: event.currentTarget.checked });
  }

  /**
   * Hide and reset the modal dialog.
   */
  onHide(): void {
    const { showModal } = this.props;

    this.setState({
      name: '',
      validated: false,
      subsidiary: undefined,
      distributionTemplate: undefined,
    });

    showModal(false);
  }

  /**
   * Set the subsidiary if this is a subsidiary distribution template.
   *
   * @param subsidiary
   */
  setSubsidiary(subsidiary?: ClientLocation): void {
    this.setState({ subsidiary });
  }

  /**
   * Set the subsidiary if this is a subsidiary distribution template.
   *
   * @param subsidiary
   */
  setDistributionTemplate(distributionTemplate?: DistributionTemplate): void {
    this.setState({
      distributionTemplate,
      name: distributionTemplate?.name ?? '',
    });
  }

  render(): JSX.Element {
    const { show, container } = this.props;
    const {
      validated,
      distributionTemplate,
      name,
      updateAreasCheck,
    } = this.state;

    return (
      <Modal
        show={show}
        onHide={this.onHide}
        container={container.current}
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {distributionTemplate
              ? MODAL_TITLE_UPDATE_DISTRIBUTION_TEMPLATE(
                  distributionTemplate.name
                )
              : MODAL_TITLE_ADD_DISTRIBUTION_TEMPLATE}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form
            id="distribution-template-modal-form"
            className="distribution-template-modal-form"
            onSubmit={this.onClickSubmit}
            noValidate
            validated={validated}
          >
            <Form.Row>
              <Col>
                <Form.Group controlId="input-number">
                  <Form.Label>
                    {DISTRIBUTION_TEMPLATE_MODAL_NAME_TITLE}
                  </Form.Label>
                  <Form.Control
                    as="input"
                    value={name}
                    placeholder={DISTRIBUTION_TEMPLATE_MODAL_NAME_PLACEHOLDER}
                    required
                    onChange={this.onChangeName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {DISTRIBUTION_TEMPLATE_MODAL_NAME_INVALID}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Form.Row>
            {distributionTemplate && (
              <Form.Row>
                <Col>
                  <Form.Group>
                    <Form.Check
                      id="update-areas-check"
                      custom
                      className="mt-2"
                      type="checkbox"
                      onChange={this.onChangeUpdateAreas}
                      checked={updateAreasCheck}
                      label={DISTRIBUTION_TEMPLATE_MODAL_UPDATE_AREAS_CHECK}
                    />
                  </Form.Group>
                </Col>
              </Form.Row>
            )}
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Col className="p-0">
            <Button
              type="submit"
              variant="primary"
              className="ci"
              form="distribution-template-modal-form"
            >
              {distributionTemplate ? BUTTON_TITLE_SUBMIT : BUTTON_TITLE_CREATE}
            </Button>
          </Col>
          <Col className="p-0">
            <Button variant="primary" className="ci" onClick={this.onHide}>
              {BUTTON_TITLE_ABORT}
            </Button>
          </Col>
        </Modal.Footer>
      </Modal>
    );
  }
}
