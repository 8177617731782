import { Area } from '../@types/Area.d';
import {
  ClientLocation,
  DistributionTemplate,
  HistoryTemplate,
  SubsidiaryDistributionTemplate,
} from '../@types/Common.d';

/**
 * Sort an array of area by their areakeys
 *
 * @param areas
 */
export const sortAreas = (areas: Area[]): Area[] =>
  areas.sort((areaA: Area, areaB: Area) => {
    if (areaA.areaKey.toLocaleLowerCase() < areaB.areaKey.toLocaleLowerCase())
      return -1;
    if (areaA.areaKey.toLocaleLowerCase() > areaB.areaKey.toLocaleLowerCase())
      return 1;

    return 0;
  });

/**
 * Sort an array of subsidiaries by their names.
 *
 * @param subsidiaries
 */
export const sortClientLocations = (
  subsidiaries: ClientLocation[]
): ClientLocation[] =>
  subsidiaries.sort(
    (subsidiaryA: ClientLocation, subsidiaryB: ClientLocation) => {
      if (
        subsidiaryA.name.toLocaleLowerCase() <
        subsidiaryB.name.toLocaleLowerCase()
      )
        return -1;
      if (
        subsidiaryA.name.toLocaleLowerCase() >
        subsidiaryB.name.toLocaleLowerCase()
      )
        return 1;

      return 0;
    }
  );

/**
 * Sort an array of distribution templates
 * by their names.
 *
 * @param distributionTemplates
 */
export const sortDistributionTemplates = (
  distributionTemplates: DistributionTemplate[]
): DistributionTemplate[] =>
  distributionTemplates.sort(
    (
      distributionTemplateA: DistributionTemplate,
      distributionTemplateB: DistributionTemplate
    ) => {
      if (
        distributionTemplateA.name.toLocaleLowerCase() <
        distributionTemplateB.name.toLocaleLowerCase()
      )
        return -1;
      if (
        distributionTemplateA.name.toLocaleLowerCase() >
        distributionTemplateB.name.toLocaleLowerCase()
      )
        return 1;

      return 0;
    }
  );

/**
 * Sort an array of subsidiary distribution
 * templates bei their names.
 *
 * @param distributionTemplates
 */
export const sortSubsidiaryDistributionTemplates = (
  distributionTemplates: SubsidiaryDistributionTemplate[]
): SubsidiaryDistributionTemplate[] =>
  distributionTemplates.sort(
    (
      distributionTemplateA: SubsidiaryDistributionTemplate,
      distributionTemplateB: SubsidiaryDistributionTemplate
    ) => {
      if (
        distributionTemplateA.name.toLocaleLowerCase() <
        distributionTemplateB.name.toLocaleLowerCase()
      )
        return -1;
      if (
        distributionTemplateA.name.toLocaleLowerCase() >
        distributionTemplateB.name.toLocaleLowerCase()
      )
        return 1;

      return 0;
    }
  );

/**
 * Sort an array of history templates by
 * heir ids
 *
 * @param historyTemplates
 */
export const sortHistoryTemplates = (
  historyTemplates: HistoryTemplate[]
): HistoryTemplate[] =>
  historyTemplates.sort(
    (historyTemplateA: HistoryTemplate, historyTemplateB: HistoryTemplate) => {
      if (historyTemplateA.id < historyTemplateB.id) return 1;
      if (historyTemplateA.id > historyTemplateB.id) return -1;
      return 0;
    }
  );
