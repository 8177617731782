import { Weekpart } from './Common.d';

export interface Message {
  payload: MessagePayload;
  type: MessageType;
}

export enum MessageType {
  MESSAGE_TYPE_INIT_AREAS = 'init_areas',
  MESSAGE_TYPE_REMOVE_AREA = 'remove_area',
  MESSAGE_TYPE_UPDATE_LOCALITIES = 'update_localities',
  MESSAGE_TYPE_UPDATE_LOCALITIES_EXTERNAL = 'update_localities_external',
  MESSAGE_TYPE_UPDATE_AREAS = 'update_areas',
  MESSAGE_TYPE_CHANGE_WEEKPART = 'change_weekpart',
  MESSAGE_TYPE_ADD_SUBSIDIARY = 'add_subsidiary',
  MESSAGE_TYPE_REMOVE_SUBSIDIARY = 'remove_subsidiary',
  MESSAGE_TYPE_ADD_ORDER = 'add_order',
  MESSAGE_TYPE_CHANGE_PRODUCT = 'change_product',
  MESSAGE_TYPE_UPDATE_PRICE = 'update_price',
  MESSAGE_TYPE_REMOVE_ALL_AREAS = 'remove_all_areas',
  MESSAGE_TYPE_HIDE_SUBSIDIARY_SELECTION = 'hide_subsidiary_selection',
  MESSAGE_TYPE_MAP_INFO = 'map_info',
}

export interface AreaMessagePayload {
  totalCirculation: number;
  areas: Area[] | ClientLocation[];
  weekpart: Weekpart;
  price?: Price;
}

export interface LocalityMessagePayload {
  totalCirculation?: number;
  weekpart: Weekpart;
  area?: Area;
  areas?: Area[];
}

export interface InitAreasPayload {
  weekpart: Weekpart;
  areas: Area[] | ClientLocation[];
}

export interface MapInfoPayload {
  totalCirculation: number;
  areas: Area[] | ClientLocation[];
  price: Price;
  weekpart: Weekpart;
}

export interface HideSubsidiarySelectionPayload {
  subsidiaryId: number;
  show: boolean;
}

export type MessagePayload =
  | Area
  | LocalityMessagePayload
  | ClientLocation
  | Weekpart
  | AreaMessagePayload
  | HideSubsidiarySelectionPayload
  | MapInfo;
