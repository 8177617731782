import * as React from 'react';

import { Modal, Button, FormControl, Col, Form } from 'react-bootstrap';

import {
  MODAL_TITLE_IMPORT,
  MODAL_IMPORT_APPEND_AREAS,
  BUTTON_TITLE_ABORT,
  BUTTON_TITLE_IMPORT,
  MODAL_IMPORT_KEYLIST_PLACEHOLDER,
  MODAL_IMPORT_KEYLIST_INVALID,
} from '../../constants/labels';
import {
  COUNTRY_CODE_DE,
  LAYER_TYPE_POSTCODE,
} from '../../constants/constants';

import { ImportModalProps, ImportModalState } from '../../@types/Modal.d';
import { AreaDescription } from '../../@types/Area.d';

/**
 * Modal dialog to bulk import a number of areakeys (area ids or postcodes) to the selection.
 */
export default class ImportModal extends React.Component<
  ImportModalProps,
  ImportModalState
> {
  constructor(props: ImportModalProps) {
    super(props);
    this.state = {
      appendToSelection: false,
      plzText: '',
      validated: false,
    };

    this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
    this.onChangeTextBox = this.onChangeTextBox.bind(this);
    this.onClickImport = this.onClickImport.bind(this);
    this.onHide = this.onHide.bind(this);
  }

  /**
   * Change event for the override or append mode of the import.
   *
   * @param event
   */
  onChangeCheckbox(event: React.ChangeEvent<HTMLInputElement>): void {
    event.stopPropagation();
    this.setState({
      appendToSelection: event.currentTarget.checked,
    });
  }

  /**
   * Change event for the text box containing the areakeys
   * @param event
   */
  onChangeTextBox(event: React.ChangeEvent<HTMLTextAreaElement>): void {
    event.stopPropagation();
    this.setState({
      plzText: event.currentTarget.value,
    });
  }

  /**
   * Click event to import the provided areakeys.
   *
   * @param event
   */
  onClickImport(event: React.FormEvent<HTMLFormElement>): void {
    const formValid = event.currentTarget.checkValidity();

    event.preventDefault();
    event.stopPropagation();

    this.setState(
      {
        validated: true,
      },
      () => {
        if (formValid) {
          const { addAreas, weekpart, client } = this.props;
          const { appendToSelection, plzText } = this.state;

          addAreas(
            plzText
              .split(/[((,|;)*\s*)$]/g)
              .filter(areaKey => areaKey)
              .map(
                areaKey =>
                  ({
                    areaKey,
                    weekpart,
                    countryCode: COUNTRY_CODE_DE,
                    type: client?.clientLayers[0].type ?? LAYER_TYPE_POSTCODE,
                  } as AreaDescription)
              ),
            appendToSelection,
            true
          );
          this.onHide();
        }
      }
    );
  }

  /**
   * Method to hide and reset the modal dialog.
   */
  onHide(): void {
    const { showModal } = this.props;

    this.setState({
      appendToSelection: false,
      plzText: '',
      validated: false,
    });

    showModal(false);
  }

  render(): JSX.Element {
    const { show, container } = this.props;
    const { appendToSelection, validated } = this.state;

    return (
      <Modal
        show={show}
        onHide={this.onHide}
        container={container.current}
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{MODAL_TITLE_IMPORT}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form
            id="import-modal-form"
            className="import-modal-form"
            onSubmit={this.onClickImport}
            noValidate
            validated={validated}
          >
            <Form.Row>
              <Col>
                <Form.Group>
                  <FormControl
                    as="textarea"
                    placeholder={MODAL_IMPORT_KEYLIST_PLACEHOLDER}
                    onChange={this.onChangeTextBox}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {MODAL_IMPORT_KEYLIST_INVALID}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col>
                <Form.Group>
                  <Form.Check
                    id="append-to-list-check"
                    custom
                    className="mt-2"
                    type="checkbox"
                    onChange={this.onChangeCheckbox}
                    checked={appendToSelection}
                    label={MODAL_IMPORT_APPEND_AREAS}
                  />
                </Form.Group>
              </Col>
            </Form.Row>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Col className="p-0">
            <Button
              type="submit"
              variant="primary"
              className="ci"
              form="import-modal-form"
            >
              {BUTTON_TITLE_IMPORT}
            </Button>
          </Col>
          <Col className="p-0">
            <Button variant="primary" className="ci" onClick={this.onHide}>
              {BUTTON_TITLE_ABORT}
            </Button>
          </Col>
        </Modal.Footer>
      </Modal>
    );
  }
}
