import '../../styles/DistributionTemplateList.scss';

import * as React from 'react';

import DistributionTemplateList from './DistributionTemplateList';
import ListHeader from '../common/listHeader/ListHeader';
import {
  DISTRIBUTION_TEMPLATE_LIST_TITLE,
  DISTRIBUTION_TEMPLATE_LIST_SEARCH_FIELD_HINT,
} from '../../constants/labels';

import {
  DistributionTemplateListContainerPorps,
  DistributionTemplateListContainerState,
} from '../../@types/DistributionTemplateList.d';

/**
 * Component to provide a container for a list of distribution templates
 */
export default class DistributionTemplateListContainer extends React.Component<
  DistributionTemplateListContainerPorps,
  DistributionTemplateListContainerState
> {
  constructor(props: DistributionTemplateListContainerPorps) {
    super(props);

    const { distributionTemplates } = this.props;
    this.state = {
      filteredDistributionTemplateList: distributionTemplates,
      searchString: '',
    };

    this.filterDistributionTemplateList = this.filterDistributionTemplateList.bind(
      this
    );
  }

  /**
   * Used to determine if the content of the list has changed. If this is the case
   * the list gets refreshed.
   *
   * @param prevProps
   */
  componentDidUpdate(prevProps: DistributionTemplateListContainerPorps): void {
    const { distributionTemplates } = this.props;

    if (
      JSON.stringify(distributionTemplates) !==
      JSON.stringify(prevProps.distributionTemplates)
    ) {
      const { searchString } = this.state;
      this.filterDistributionTemplateList(searchString);
    }
  }

  /**
   * Filter function to search the list for a specific entry.
   * This function is triggered y the ListSearch component.
   *
   * @param searchString
   */
  filterDistributionTemplateList(searchString: string): void {
    const { distributionTemplates } = this.props;
    let filteredDistributionTemplateList;
    if (searchString.length <= 0)
      filteredDistributionTemplateList = distributionTemplates;
    else {
      const searchStringRegex = searchString
        .split('')
        .map(s => `[${s}]\\w*`)
        .join('');
      const regex = new RegExp(`\\w*${searchStringRegex}`, 'gi');
      filteredDistributionTemplateList = distributionTemplates.filter(
        ({ name }) => name.match(regex)
      );
    }

    this.setState({ filteredDistributionTemplateList, searchString });
  }

  render(): JSX.Element {
    const {
      showDistributionTemplates,
      planningRestriction,
      showNewDistributionTemplateModal,
      deleteDistributionTemplate,
      hideDistributionTemplateList,
      applyDistributionTemplate,
      updateDistributionTemplate,
    } = this.props;
    const { filteredDistributionTemplateList, searchString } = this.state;
    return (
      <div
        className={`d-flex flex-column collapse-content ${
          showDistributionTemplates ? 'shown' : 'hidden'
        } width distribution-template-list-container`}
      >
        {showDistributionTemplates
          ? [
              <ListHeader
                key={1}
                hideList={hideDistributionTemplateList}
                listTitle={DISTRIBUTION_TEMPLATE_LIST_TITLE}
                searchHint={DISTRIBUTION_TEMPLATE_LIST_SEARCH_FIELD_HINT}
                searchString={searchString}
                filterList={this.filterDistributionTemplateList}
              />,
              <DistributionTemplateList
                key={2}
                distributionTemplates={filteredDistributionTemplateList}
                planningRestriction={planningRestriction}
                showNewDistributionTemplateModal={
                  showNewDistributionTemplateModal
                }
                deleteDistributionTemplate={deleteDistributionTemplate}
                applyDistributionTemplate={applyDistributionTemplate}
                updateDistributionTemplate={updateDistributionTemplate}
              />,
            ]
          : null}
      </div>
    );
  }
}
