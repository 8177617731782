import * as React from 'react';

import { Modal, Button, Col } from 'react-bootstrap';

import {
  BUTTON_TITLE_CONFIRM,
  BUTTON_TITLE_ABORT,
} from '../../constants/labels';

import { ConfirmationModalProps } from '../../@types/Modal.d';

/**
 * Modal dialog to show a confirmation. This component needs a callback
 * for conirmation.
 *
 * @param props
 */
const ConfirmationModal: React.FC<ConfirmationModalProps> = (
  props: ConfirmationModalProps
) => {
  const { show, showModal, title, content, callback, container } = props;

  /**
   * Hide the modal dialog.
   */
  const onHide = (): void => {
    showModal(false, undefined, undefined, undefined);
  };

  /**
   * Click action to confirm the action.
   *
   * @param event
   */
  const onClickConfirm = (event: React.MouseEvent): void => {
    if (callback) callback();

    onHide();
    event.preventDefault();
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      container={container.current}
      backdrop="static"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{content}</Modal.Body>
      <Modal.Footer>
        <Col className="p-0">
          <Button className="ci modal-footer-button" onClick={onClickConfirm}>
            {BUTTON_TITLE_CONFIRM}
          </Button>
        </Col>
        <Col className="p-0">
          <Button className="ci modal-footer-button" onClick={onHide}>
            {BUTTON_TITLE_ABORT}
          </Button>
        </Col>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
