// eslint-disable-next-line import/no-cycle
import { ColorStyle } from './@types/Common.d';

const config = {
  general: {
    subsidiaryMode: process.env.REACT_APP_SUBSIDIARY_MODE === 'true',
    apiUrl: process.env.REACT_APP_API_URL,
    apiKey: process.env.REACT_APP_API_KEY,
    printToConsole: process.env.REACT_APP_PRINT_TO_CONSOLE === 'true',
    defaultWeekpart: process.env.REACT_APP_DEFAULT_WEEKPART,
    showIsochrone: process.env.REACT_APP_SHOW_ISOCHRONE === 'true',
    isShop: process.env.REACT_APP_IS_SHOP === 'true',
    showPrice: process.env.REACT_APP_SHOW_PRICE === 'true',
    defaultFeatureColorStyle: {
      fill: process.env.REACT_APP_DEFAULT_COLOR_FILL ?? 'rgba(0, 0, 0, 0)',
      fillSelected:
        process.env.REACT_APP_DEFAULT_COLOR_FILL_SELECTED ??
        'rgba(88, 168, 200, 0.5)',
      stroke:
        process.env.REACT_APP_DEFAULT_COLOR_STROKE ?? 'rgba(88, 168, 200, 1.0)',
      strokeSelected:
        process.env.REACT_APP_DEFAULT_COLOR_STROKE_SELECTED ??
        'rgba(88, 168, 200, 1.0)',
      strokeWidth: process.env.REACT_APP_DEFAULT_COLOR_STROKE_WIDTH
        ? +process.env.REACT_APP_DEFAULT_COLOR_STROKE_WIDTH
        : 1,
      zIndex: 100,
    } as ColorStyle,
  },
  map: {
    defaultLayer: process.env.REACT_APP_DEFAULT_LAYER ?? 'DE_POSTCODE',
    buttons: {
      fitSelection: process.env.REACT_APP_FIT_SELECTION === 'true',
      exportSelection: process.env.REACT_APP_EXPORT_SELECTION === 'true',
      fullscreen: process.env.REACT_APP_FULLSCREEN === 'true',
      importPLZ: process.env.REACT_APP_IMPORT_PLZ === 'true',
      printMap: process.env.REACT_APP_PRINT_MAP === 'true',
      searchAddress: process.env.REACT_APP_SEARCH_ADDRESS === 'true',
      showSubsidiaries: process.env.REACT_APP_SHOW_SUBSIDIARIES === 'true',
      zoomToCurrentLocation:
        process.env.REACT_APP_ZOOM_TO_CURRENT_LOCATION === 'true',
      selectWeekpart: process.env.REACT_APP_SELECT_WEEKPART === 'true',
      isochrone: process.env.REACT_APP_ISOCHRONE === 'true',
      drawPerimeter: process.env.REACT_APP_PERIMETER === 'true',
    },
    tooltips: {
      showTooltips: process.env.REACT_APP_SHOW_TOOLTIPS === 'true',
      weekpartSensitive: process.env.REACT_APP_WEEKPART_SENSITIVE === 'true',
    },
  },
};
export default config;
