import * as React from 'react';

import { ListGroup, Row, Col } from 'react-bootstrap';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { SubsidiaryDistributionTemplateListItemProps } from '../../../@types/Modal.d';

/**
 * Component to show a subsidiary distribution template as a list item
 *
 * @param props
 */
const SubsidiaryDistributionTemplateListItem: React.FC<SubsidiaryDistributionTemplateListItemProps> = (
  props: SubsidiaryDistributionTemplateListItemProps
) => {
  const {
    subsidiaryDistributionTemplate,
    selected,
    subsidiary,
    selectSubsidiaryDistributionTemplate,
    removeSubsidiaryDistributionTemplate,
  } = props;

  /**
   * Click action to select a subsidiary distribution template
   *
   * @param event
   */
  const onClickItem = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ): void => {
    selectSubsidiaryDistributionTemplate(subsidiaryDistributionTemplate);
    event.preventDefault();
  };

  /**
   * Click action to remove a subsidiary distribution template
   *
   * @param event
   */
  const onClickRemove = (event: React.MouseEvent): void => {
    event.stopPropagation();

    if (subsidiary)
      removeSubsidiaryDistributionTemplate(
        subsidiaryDistributionTemplate,
        subsidiary
      );
  };

  /**
   * Key down action to remove a subsidiary distribution template
   *
   * @param event
   */
  const onKeyDownRemove = (event: React.KeyboardEvent): void => {
    event.stopPropagation();

    if (subsidiary)
      removeSubsidiaryDistributionTemplate(
        subsidiaryDistributionTemplate,
        subsidiary
      );
  };

  return (
    <ListGroup.Item
      onClick={onClickItem}
      className="selectable hover-item"
      active={selected}
    >
      <Row className="no-gutters">
        <Col sm={11}>{subsidiaryDistributionTemplate.name}</Col>
        <Col sm={1}>
          <div
            role="button"
            className="icon-remove hover-icon"
            tabIndex={0}
            onClick={onClickRemove}
            onKeyUp={onKeyDownRemove}
          >
            <FontAwesomeIcon icon={faTrash} />
          </div>
        </Col>
      </Row>
    </ListGroup.Item>
  );
};

export default SubsidiaryDistributionTemplateListItem;
